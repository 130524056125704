import React, { useEffect, useRef, useState } from 'react';

const VideoContent = ({ content }) => {
    const { title, url, files } = content;
    const iframeRef = useRef(null);
    const [YTReady, setYTReady] = useState(false);
    const storageKey = `youtube-video-${title}`;

    // Function to convert YouTube links to embed URLs
    const getEmbedUrl = (url, startTime) => {
        let videoId = url.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
            videoId = videoId.substring(0, ampersandPosition);
        }
        return `https://www.youtube.com/embed/${videoId}?start=${startTime}&enablejsapi=1&origin=${window.location.origin}`;
    };

    // Check if the provided URL is a YouTube link
    const isYouTubeLink = (url) => {
        return url.includes('youtube.com') || url.includes('youtu.be');
    };

    // Load YouTube IFrame API
    useEffect(() => {
        const loadYT = () => {
            if (!window.YT) {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
            const checkYT = setInterval(() => {
                if (window.YT && window.YT.Player) {
                    clearInterval(checkYT);
                    setYTReady(true);
                }
            }, 100);
        };

        loadYT();
    }, []);

    useEffect(() => {
        if (YTReady && iframeRef.current) {
            const iframe = iframeRef.current;
            const storedTime = localStorage.getItem(storageKey) || 0;

            const onPlayerReady = (event) => {
                event.target.seekTo(parseFloat(storedTime), true);
            };

            const onPlayerStateChange = (event) => {
                if (event.data === window.YT.PlayerState.PLAYING) {
                    const interval = setInterval(() => {
                        const currentTime = event.target.getCurrentTime();
                        localStorage.setItem(storageKey, currentTime);
                    }, 1000);
                    
                    const clearOnPauseOrEnd = () => {
                        clearInterval(interval);
                    };
                    event.target.addEventListener('onStateChange', (stateChangeEvent) => {
                        if (stateChangeEvent.data === window.YT.PlayerState.PAUSED || stateChangeEvent.data === window.YT.PlayerState.ENDED) {
                            clearOnPauseOrEnd();
                        }
                    });
                }
            };

            const player = new window.YT.Player(iframe, {
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange,
                }
            });

            return () => {
                player.destroy();
            };
        }
    }, [YTReady, storageKey]);

    return (
        <div className="content-box">
            <h5>{title}</h5>
            <div style={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: 0,
                overflow: 'hidden',
                maxWidth: '100%'
            }}>
                {isYouTubeLink(url) ? (
                    <iframe
                        ref={iframeRef}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', paddingBottom: '1%' }}
                        src={getEmbedUrl(url, 0)}
                        title={title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <video style={{ width: '100%', height: '100%' }} controls>
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            {files && files.length > 0 && (
                <div className="files-section mt-3">
                    <h6>Archivos adjuntos:</h6>
                    <ul>
                        {files.map((file, index) => (
                            <li key={index}>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                    {file.filename}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default VideoContent;



