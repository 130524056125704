import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import Main from './main';
import bannerBg from '../../../assets/img/banner01.png';
import Footer from "../../../components/MSFooter";

const Courses = () => {

    return (
        <React.Fragment>
            <Header parentMenu={'courses'}/>
            <SiteBreadcrumb pageTitle="Explorá nuestros cursos" pageName="Cursos" breadcrumbsImg={bannerBg} />
            <Main/>
            <Footer/>
        </React.Fragment>
    );
}

export default Courses;