import React from 'react';

const Modal = ({
                   isOpen,
                   onClose,
                   selectedName,
                   selectedLicense,
                   selectedDueDate,
                   setSelectedLicense,
                   setSelectedName,
                   setSelectedDueDate,
                   selectedDescription,
                   setSelectedDescription,
                   onCreate
               }) => {

    const handleSave = () => {
        onCreate({
            name: selectedName,
            description: selectedDescription,
            licenseCount: selectedLicense,
            dueDate: selectedDueDate
        });
    };

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} tabIndex="-1"
             style={{display: isOpen ? 'block' : 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Crear Código de Activación</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nombre:</label>
                            <input type="text" className="form-control" value={selectedName}
                                   onChange={(e) => setSelectedName(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Descripción:</label>
                            <input type="text" className="form-control" value={selectedDescription}
                                   onChange={(e) => setSelectedDescription(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="licenseCount" className="form-label">Número de Licencias:</label>
                            <input type="text" className="form-control" value={selectedLicense}
                                   onChange={(e) => setSelectedLicense(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dueDate" className="form-label">Fecha de Vencimiento:</label>
                            <input type="date" className="form-control" value={selectedDueDate}
                                   onChange={(e) => setSelectedDueDate(e.target.value)}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn2 btn-delete me-2" onClick={onClose}>Cerrar</button>
                        <button type="button" className="btn2 btn-delete me-2" onClick={handleSave}>Guardar Cambios
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
