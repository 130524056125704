import React from "react";
import {Link} from "react-router-dom";

const CourseSingleTwo = (props) => {
    const {
        courseClass,
        courseImg,
        courseTitle,
        courseCategory,
        courseDuration,
        courseDetail,
        courseId,
        completed,
        fav // Añadimos el parámetro fav
    } = props;

    return (
        <div className={courseClass ? courseClass : "courses-item"}>
            <div className="img-part" style={{ position: 'relative' }}>
                <img src={courseImg} alt={courseTitle || "Course Image"} />
                {completed ? (
                    <span className="completed-tag">Finalizado</span>
                ) : (
                    <span className="in-progress-tag">En curso</span>
                )}
                <i 
                    className={`fa ${fav ? 'fa-heart' : 'fa-heart-o'} favorite-icon`} 
                ></i>
            </div>
            <div className="content-part">
                <h3 className="title">
                    <Link to={`/course-flow/${courseId}`}>
                        {courseTitle || "Course Title"}
                    </Link>
                </h3>
                <ul className="meta-part no-overflow">
                    <li>
                        <span>
                            {courseCategory ? courseCategory : "Unknown Category"}
                        </span>
                    </li>
                </ul>
                <div className="bottom-part">
                    <div className="info-meta">
                        <div dangerouslySetInnerHTML={{ __html: courseDetail }} />
                    </div>
                    <div className="info-meta">
                        <ul className="meta-part">
                            <li>
                                <span className="price">
                                    <em>Duración: </em>{courseDuration ? `${courseDuration} hs` : "10.00 hs"}
                                </span>
                            </li>
                            <li className="btn-part">
                                <Link to={`/course-flow/${courseId}`}>
                                    {props.btnText}
                                    <i className="flaticon-right-arrow"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseSingleTwo;

