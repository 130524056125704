import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import CampaignsCreator from './campaignCreator';
import Swal from "sweetalert2";

const CampaignsMain = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_URL}/campaigns/get`);
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error('Error al obtener campañas:', error.message);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleEdit = (campaign) => {
    setSelectedCampaign(campaign);
    setIsEditing(true);
  };

  const handleCreate = () => {
    setIsCreating(true);
    setSelectedCampaign(null);
  };

  const handleCancelCreate = () => {
    setIsCreating(false);
  };

  const handleDelete = async (campaign) => {
    try {
      const id = campaign.id;
      const response = await axios.post(`${process.env.REACT_APP_ROOT_URL}/campaigns/delete`, { id });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          position: "center",
          text: `Campaña Eliminada Correctamente!`,
          showConfirmButton: true,
          confirmButtonText: `OK`,
          customClass: {
            confirmButton: 'custom-button-confirmed',
          },
          timer: 2000,
        });
        
        fetchCampaigns()
        handleCancelCreate();
      }
    } catch (error) {
      console.error('Error al borrar campañas:', error.message);
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Error al eliminar la campaña. Por favor intente nuevamente más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
          confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    }
  };

  const handleCampaignSave = () => {
    fetchCampaigns()
  }

  return (
    <div className="p-2">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign) => (
            <tr key={campaign.id}>
              <td><h6>{campaign.name}</h6></td>
              <td>
                <Button onClick={() => handleEdit(campaign)}>
                  Editar
                </Button>
                <Button variant="danger" className="mx-1" onClick={() => handleDelete(campaign)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedCampaign && (
        <CampaignsCreator
          initialData={selectedCampaign}
          isEditing
          onCancel={() => setSelectedCampaign(null)}
          onCampaignSave={handleCampaignSave}
        />
      )}
      {isCreating && (
        <CampaignsCreator onCancel={handleCancelCreate} onCampaignSave={handleCampaignSave}/>
      )}
      <Button onClick={handleCreate}>
        Crear Nueva Campaña
      </Button>
    </div>
  );
};

export default CampaignsMain;