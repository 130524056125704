import React from 'react';

const TextContent = ({ content }) => {
    const { title, text, files } = content;
    return (
        <div className="content-box text-content pe-5">
            <h5>{title}</h5>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {files && files.length > 0 && (
                <div className="files-section mt-3">
                    <h6>Archivos adjuntos:</h6>
                    <ul>
                        {files.map((file, index) => (
                            <li key={index}>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                    {file.filename}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TextContent;
