import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import CourseSingleTwo from "../../../components/Courses/CourseSingleTwo";
import ReactPaginate from "react-paginate";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import courseImg1 from "../../../assets/img/courses/1.jpg";

const CoursePart = () => {
    const [fetchData, setFetchData] = useState([]);
    const [fetchedCategories, setFetchedCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState("Todas las Categorías");
    const [currentPage, setCurrentPage] = useState(0);
    const coursesPerPage = 9;
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");


    //MODIFICAR
    const userId = useUserLocalStorage();
    const location = useLocation();

    useEffect(() => {
        const fetchDataAndCategories = async () => {
            try {
                // Fetch all courses data
                const coursesResponse = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_GET_ALL}`
                );
                const coursesData = await coursesResponse.json();

                // Filter active courses
                const activeCoursesData = coursesData.filter(course => course.active === true);

                // Filter courses in which user is not enrolled
                //const filteredCoursesData = activeCoursesData.filter(course => !course.enrolledUsers.includes(userId));
                
                setFetchData(activeCoursesData);

                // Fetch categories data
                const categoriesResponse = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_CATEGORIES_GET_ALL}`
                );
                const categoriesData = await categoriesResponse.json();

                const filteredCategories = categoriesData.filter(
                    (category) => category.active === true
                );

                filteredCategories.unshift({
                    id: 12341,
                    name: "Todas las Categorías",
                });
                
                setFetchedCategories(filteredCategories);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDataAndCategories();
    }, [userId]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const category = params.get("category");
        if (category) {
            setActiveCategory(category);
        }
    }, [location.search]);

    // Funcion modificada para buscar cursos
    const renderCoursePanel = () => {
        const filteredCourses = fetchData
        // Filtra por nombre, convirtiendo el nombre de los cursos y el filtro a minuscula para que no importen las mayusculas en la comparacion
        .filter((course) => {
            const courseNameLower = course.name.toLowerCase();
            const filterTextLower = filterText.toLowerCase();
        return courseNameLower.includes(filterTextLower);
        })
        // Filtra las categorias activas
        .filter((course) => {
            return (
                activeCategory === "Todas las Categorías" ||
                course.categories && course.categories.includes(activeCategory)
            );
        });

        const offset = currentPage * coursesPerPage;
        const currentPageCourses = filteredCourses.slice(
            offset,
            offset + coursesPerPage
        );


        return (
            <div className="row">
                {currentPageCourses.map((course) => (
                    <div key={course.id} className="col-lg-4 col-md-6">
                        <CourseSingleTwo
                            key={course.id}
                            courseClass="courses-item md-mb-30"
                            courseImg={course.image === "" ? courseImg1 : course.image}
                            courseTitle={truncateText(course.name, 20) || "Curso en desarrollo"}
                            courseDuration={course.duration || "10"}
                            courseId={course.id || "112"}
                            courseDetail={truncateText(course.description, 40)}
                            courseCategory={
                                course.categories && course.categories.length > 0
                                    ? course.categories.join(", ")
                                    : ""
                            }
                        />
                    </div>
                ))}
            </div>
        );
    };

    const handleTabClick = (category) => {
        setActiveCategory(category);
        setCurrentPage(0);
    };

    const handlePageChange = ({ selected }) => {
        window.scrollTo({
            top: 250,
            behavior: "smooth",
        });
        setCurrentPage(selected);
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const totalCourses =
        activeCategory === "Todas las Categorías"
            ? fetchData.length
            : fetchData.filter((course) =>
                course.categories.includes(activeCategory)
            ).length;
    const pageCount = Math.ceil(totalCourses / coursesPerPage);

    return (
        <div
            id="rs-popular-course"
            className="rs-popular-courses style1 orange-style md-pt-70 md-pb-50"
        >
            <div className="">
                <Tabs>
                    <div className="row">
                        <div className="col-lg-3 col-md-12 colPink pt-50 pb-100 pl-50 pr-50">
                            <TabList className="gridFilter mb-10 mb-10">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Buscar por nombre" value={filterText} onChange={(event) => setFilterText(event.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text pt-10 pb-10" id="basic-addon2"><i className="fa fa-search"></i></span> 
                                    </div>
                                </div>
                                {fetchedCategories.map((category) => (
                                    <Tab key={category.id} onClick={() => handleTabClick(category.name)}>
                                        <button>{category.name}</button>
                                    </Tab>
                                ))}
                            </TabList>
                        </div>
                        
                        <div className="col-lg-9 col-md-12 colWhite pt-50 pb-100 pr-50 pl-50">
                            <>
                                {fetchedCategories.map((category) => (
                                    <TabPanel key={category.id}>
                                        <div>
                                            {renderCoursePanel()}
                                            {/* <ReactPaginate
                                                className="pagination"
                                                // previousLabel={"Previous"}
                                                // nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                            /> */}
                                        </div>
                                    </TabPanel>
                                ))}
                            </>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
};

export default CoursePart;

