import React from "react";
import CoursePart from "./table";
import ScrollToTop from "../../../components/Common/ScrollTop";

const Main = () => {
    return (
        <React.Fragment>
            <CoursePart/>
            <ScrollToTop scrollClassName="scrollup orange-color"/>
        </React.Fragment>
    );
};

export default Main;
