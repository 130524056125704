import React from "react";
import useUserRoleLocalStorage from "../../UseUserRoleLocalStorage/UseUserRoleLocalStorage.js";
import NavBar from "./NavBar";

const MainNavBar = (props) => {
    const {parentMenu} = props;
    const userRole = useUserRoleLocalStorage();

    const isAdmin = () => {
        return userRole === "admin" || userRole === "superadmin";
    };

    return (
        <NavBar isAdmin={isAdmin()} isLoggedIn={userRole} parentMenu={parentMenu} />
    )
};

export default MainNavBar;

