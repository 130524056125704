import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import Modal from "../../../components/ModalModifyCode/Modal.js";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';
import dotenv from 'dotenv'
import UpdateModal from "../../../components/admin/emails/update";

dotenv.config()

const ManageEmailsTable = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedEmailId, setSelectedEmailId] = useState('');
    const [selectedFrom, setSelectedFrom] = useState('');
    const [selectedMessage, setSelectedMessage] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [loading, setLoading] = useState(true);
    const [emails, setEmails] = useState([]);

    //MODIFICAR
    const userId = useUserLocalStorage();

    useEffect(() => {
        if (userId) {
            showEmailsData();
        }
    }, [userId]);

    const getEmailsUrl = () => {
        if (userId) {
            return `${process.env.REACT_APP_ROOT_URL}/emails`;
        } else {
            return null;
        }
    };

    const showEmailsData = async () => {
        try {
            const response = await fetch(
                getEmailsUrl()
            );
            const data = await response.json();
            setEmails(data);

        } catch (error) {
            console.error("Error fetching users data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        showEmailsData();
    }, []);

    // const ExportCSV = ({data}) => {
    //     const headers = [
    //         {label: 'Nombre', key: 'name'},
    //         {label: 'Licencias', key: 'licenseCount'},
    //         {label: 'Vencimiento', key: 'dueDate'}
    //     ];
    //
    //     return (
    //         <CSVLink data={codesData} headers={headers} filename={"Códigos de Activación.csv"}>
    //             Descargar CSV
    //         </CSVLink>
    //     );
    // };


    //DataTables
    const columns = [
        {
            name: "Flow",
            selector: (row) => row.flow,
            sortable: true,
            reorder: true,
        },
        {
            name: "Asunto",
            selector: (row) => row.subject,
            sortable: true,
            reorder: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="">
                    <button className="btn2 btn-modify me-2" onClick={() => handleModify(row.id)} data-bs-toggle="modal" data-bs-target="#modifyModal">
                        <i className="fa fa-edit"></i>
                    </button>
                </div>
            ),
            ignoreRowClick: true,
        },

    ];

    // Modify
    const handleModify = (emailId) => {
        const email = emails.find((email) => email.id === emailId);
        //ID
        setSelectedEmailId(email.id)
        //FROM
        setSelectedFrom(email.from)
        //SUBJECT
        setSelectedSubject(email.subject)
        //MESSAGE
        setSelectedMessage(email.message);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedEmailId(null);
    };

    const handleSaveChanges = async () => {
        try {
            const requestData = {
                id: selectedEmailId,
                from: selectedFrom,
                subject: selectedSubject,
                message: selectedMessage,
            };

            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}/emails/update`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                }
            );

            if (response.ok) {
                handleModalClose();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Email modificado con éxito!",
                    showConfirmButton: false,
                    timer: 1000,
                }).then(() => {
                    window.location.href = "/admin/emails";
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al modificar el Email",
                    text: "No se pudo actualizar el Email. Error en el servidor",
                });
                console.error("Failed to update email");
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error al modificar el Email",
                text: "No se pudo actualizar el Email. Error en el servidor.",
            });
            console.error("Error updating email:", error);
        }
    };

    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#0142FC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#f0f0f0",
            },
            action: {
                button: "#0142FC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder />
            ) : ( <></>
                // <div>
                //     <ExportCSV data={codesData}/>
                //     <DataTable
                //         columns={columns}
                //         data={codesData}
                //         noDataComponent="Cargando..."
                //         responsive={true}
                //         theme={"tradeTheme"}
                //         customStyles={customStyles}
                //         style={{fontSize: "18px"}}
                //
                //     />
                // </div>
            )}
            <UpdateModal
                id="modifyModal"
                isOpen={modalOpen}
                onClose={handleModalClose}
                emailId={selectedEmailId}
                selectedSubject={selectedSubject}
                setSelectedSubject={setSelectedSubject}
                selectedMessage={selectedMessage}
                setSelectedMessage={setSelectedMessage}
                handleSaveChanges={handleSaveChanges}
            />
        </div>
    );
};

export default ManageEmailsTable;
