import React, {useState} from 'react';
import Swal from "sweetalert2";
import ButtonLink from '../../../components/ButtonCreate/button.js';
import Modal from '../../../components/ModalCreateCode/ModalCreateCode.js';
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";

const CreateModal = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [selectedDueDate, setSelectedDueDate] = useState('');

    const [newActivationCode, setNewActivationCode] = useState({
        name: '',
        description: '',
        licenseCount: '',
        dueDate: ''
    });

    const handleNewActivationCodeChange = (e) => {
        const {name, value} = e.target;
        setNewActivationCode(prevState => ({
            ...prevState,
            [name]: value
        }), () => {
        
        });
    };

    const userId = useUserLocalStorage();

    const postCodesUrl = () => {
        if (userId) {
            return `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_ACTIVATIONCODES_POST.replace('<userId>', userId)}`;
        } else {
            return null;
        }
    };

    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) {
            return '';
        }
        const formattedDate = new Date(date).toISOString().slice(0, 10).replace(/-/g, "");
        return formattedDate;
    };


    const handleCreateNewActivationCode = (newActivationCode) => {
        const url = postCodesUrl();

        if (url) {
            const formattedDate = formatDate(new Date(newActivationCode.dueDate));
            
            const dataToSend = {
                name: newActivationCode.name,
                description: newActivationCode.description,
                licenseCount: newActivationCode.licenseCount,
                dueDate: formattedDate
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            })
                .then(response => {
                    if (response.ok) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "¡Código de activación creado con éxito!",
                            text: `El código de activación ha sido creado exitosamente.`,
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            window.location.href = "/admin/activationCodes";
                        });
                    } else {
                        Swal.fire({
                            title: "¡Ups! Algo salió mal",
                            text: `Lo sentimos, no se pudo crear el código de activación en este momento. Por favor, inténtalo de nuevo más tarde.`,
                            icon: "error",
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    
                })
                .catch(error => {
                    console.error('Error al crear el nuevo código de activación:', error);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, ocurrió un error al crear el código de activación. Por favor, inténtalo de nuevo más tarde.`,
                        icon: "error",
                    });
                });
        } else {
            console.error('No se pudo obtener la URL para la solicitud POST.');
            Swal.fire({
                title: "¡Ups! Algo salió mal",
                text: `No se pudo obtener la URL para la solicitud POST. Por favor, inténtalo de nuevo más tarde.`,
                icon: "error",
            });
        }
    };


    const handleButtonClick = () => {
        
        setModalOpen(true);
    };

    return (
        <React.Fragment>
            <ButtonLink to="#" onClick={handleButtonClick}>Crear nuevo</ButtonLink>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onCreate={handleCreateNewActivationCode}
                newActivationCode={newActivationCode}
                onChange={handleNewActivationCodeChange}
                selectedLicense={selectedLicense}
                setSelectedLicense={setSelectedLicense}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                selectedDescription={selectedDescription}
                setSelectedDescription={setSelectedDescription}
                selectedDueDate={selectedDueDate}
                setSelectedDueDate={setSelectedDueDate}
            />
        </React.Fragment>
    );
}

export default CreateModal;


