// ButtonLink.js
import React from 'react';
import {Link} from 'react-router-dom';
import styles from "./button.module.css";

const ButtonLink = ({to, children}) => {
    return (
        <div className={styles.paddingSection}>
            <div className="">
                <Link to={to}>
                    <button className="btn-shop">{children}</button>
                </Link>
            </div>
        </div>
    );
}

export default ButtonLink;
