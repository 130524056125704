import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';

const QuizContent = ({ content: quizData, handleCompletion }) => {
    const [selectedAnswers, setSelectedAnswers] = useState(new Array(quizData.questions.length).fill(null));
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0); // Track total correct answers
    const [allOptionsSelected, setAllOptionsSelected] = useState(false); // Track if all options are selected

    useEffect(() => {
        if (isSubmitted) {
            handleCompletion(); // Call handleCompletion function when quiz is submitted
        }
    }, [totalCorrectAnswers, isSubmitted, handleCompletion]);

    useEffect(() => {
        const areAllOptionsSelected = selectedAnswers.every(answerIndex => answerIndex !== null);
        setAllOptionsSelected(areAllOptionsSelected);
    }, [selectedAnswers]);

    const handleOptionSelect = (questionIndex, answerIndex) => {
        if (!isSubmitted) {
            const newAnswers = [...selectedAnswers];
            newAnswers[questionIndex] = answerIndex === newAnswers[questionIndex] ? null : answerIndex;
            setSelectedAnswers(newAnswers);
        }
    };

    const handleSubmit = () => {
        setIsSubmitted(true);
        const correctAnswers = selectedAnswers.reduce((acc, selectedAnswerIndex, questionIndex) => {
            const correctAnswer = quizData.questions[questionIndex].correctAnswer;
            const selectedAnswer = quizData.questions[questionIndex].answers[selectedAnswerIndex];
            if (selectedAnswer === correctAnswer) {
                return acc + 1;
            }
            return acc;
        }, 0);

        setTotalCorrectAnswers(correctAnswers);
    };

    return (
        <div className="quiz-content">
            {/* Render quiz questions and options */}
            {quizData.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question-container mt-1">
                    <h5 className='mb-0 pb-1 pt-3'>{question.question}</h5>
                    <div className="options-container">
                        {question.answers.map((answer, answerIndex) => {
                            const isCorrect = isSubmitted && quizData.questions[questionIndex].correctAnswer === answer;
                            const isSelected = selectedAnswers[questionIndex] === answerIndex;
                            const isIncorrect = isSubmitted && !isCorrect && isSelected; 
                            return (
                                <div
                                    key={answerIndex}
                                    className={`option ${isSelected ? 'selected' : ''} ${isCorrect ? 'correct' : ''} ${isIncorrect ? 'incorrect' : ''}`} // Added class for incorrect answers
                                    onClick={() => handleOptionSelect(questionIndex, answerIndex)}
                                    style={{
                                        backgroundColor: isSelected ? '#86c0fe' : 'initial',
                                        color: isIncorrect ? 'red' : 'initial', 
                                        border: isIncorrect ? '2px solid red' : isCorrect ? '2px solid green' : isSelected ? '2px solid #86c0fe' : 'none',
                                    }}
                                >
                                    {answer}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
            {/* Submit button */}
            <Button className="btn-course" onClick={handleSubmit} disabled={!allOptionsSelected || isSubmitted}>Validar</Button>
        </div>
    );
};

export default QuizContent;



