import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import AdminAllUsers from './table';

const Main = () => {

    return (
        <React.Fragment>
            <AdminAllUsers/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default Main;