import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import Main from './main';
import bannerbg from '../../../assets/img/banner02.png';
import Footer from "../../../components/MSFooter";

const Create = () => {
    return (
        <React.Fragment>
            <Header parentMenu='create' />
            <SiteBreadcrumb pageTitle="Modificar" pageName="Modificar Curso" titleClass="page-title white-color" breadcrumbsImg={bannerbg} />
            <Main/>
            <Footer/>
        </React.Fragment>
    );
}

export default Create;
