import React, {useEffect, useState} from "react";
import DataTable, {createTheme} from "react-data-table-component";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';
import axios from "axios";

const ReportByCourse = () => {
    ////////////////////////////
    // GET USERS DATA
    ////////////////////////////
    const [loading, setLoading] = useState(true);
    const [reportData, setReportData] = useState([]);

    const getReport = async () => {
        try {
            const endpoint = `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_REPORT_BY_COURSE}`
            const response = await axios.get(endpoint)
            const data = await response.data;
            setReportData(data);
        } catch (error) {
            console.error("Error fetching report data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getReport();
        setLoading(false);
    }, []);

    const ExportCSV = ({ data }) => {
        const headers = [
          { label: 'Nombre', key: 'name' },
          { label: 'Usuarios Enrolados', key: 'usersEnrolled' },
          { label: 'Progreso', key: 'progress' },
          { label: 'Estado', key: 'active' },
          { label: 'Usuarios', key: 'details' },
        ];

        return (
          <CSVLink data={data} headers={headers} filename={"Métricas por Curso.csv"}>
            Descargar CSV
          </CSVLink>
        );
    };

    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////

    const columns = [
        {
            name: "Curso",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
            wrap: true
        },
        {
            name: "Alumnos Enrolados",
            selector: (row) => row.usersEnrolled,
            sortable: true,
            reorder: true,
        },
        {
            name: "Completado",
            selector: (row) => `${row.progress}%`,
            sortable: true,
            reorder: true,
        },
        {
            name: "Estado",
            selector: (row) => row.active,
        },
    ];


    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#5517DC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#cccccc",
            },
            action: {
                button: "#5517DC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    const ExpandedComponent = ({data}) => (
        <div className="p-5">
            <tr>
                <td><b>Usuario</b></td>
                <td><b>Progreso</b></td>
            </tr>
            {data.users.map(user => (
                <tr>
                    <td className="p-2" key={user.name}>{user.name}</td>
                    <td className="p-2" style={{textAlign: "center"}} key={user.completed}>{user.completed}%</td>
                </tr>
            ))}
        </div>
    );

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder/>
            ) : (
                <div>
                <ExportCSV data={reportData} />
                <DataTable
                    columns={columns}
                    data={reportData}
                    noDataComponent="Cargando..."
                    responsive={true}
                    theme={"tradeTheme"}
                    style={{fontSize: "18px"}}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    defaultSortFieldId={"Curso"}
                />
                </div>
            )}
        </div>
    );
};

export default ReportByCourse;
