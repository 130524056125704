const Modal = ({
                   id,
                   isOpen,
                   onClose,
                   selectedCode,
                   selectedLicense,
                   selectedDueDate,
                   setSelectedLicense,
                   setSelectedCode,
                   setSelectedDueDate,
                   setSelectedDueDateObject,
                   handleSaveChanges
               }) => {

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} id={id} tabIndex="-1" aria-labelledby={`${id}Label`}
             aria-hidden="true" style={{display: isOpen ? 'block' : 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={`${id}Label`}>Editar Código de Activación</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Nombre: {selectedCode && selectedCode.name}</p>
                        <div className="mb-3">
                            <label htmlFor="licenseCount" className="form-label">Número de Licencias:</label>
                            <input type="text" className="form-control" id="licenseCount" value={selectedLicense}
                                   onChange={(e) => setSelectedLicense(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dueDate" className="form-label">Fecha de Vencimiento:</label>
                            <input type="date" className="form-control" id="dueDate" value={selectedDueDate}
                                   onChange={(e) => {
                                       setSelectedDueDate(e.target.value);
                                       const formattedDate = e.target.value.split('-').join(''); // Quitar los guiones del formato de fecha
                                       setSelectedDueDateObject(formattedDate);
                                   }}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn-shop" onClick={onClose}>Cerrar</button>
                        <button type="button" className="btn-shop" onClick={handleSaveChanges}>Guardar
                            Cambios
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;




