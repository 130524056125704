import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from "sweetalert2";

const GroupViewer = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupMembers, setGroupMembers] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [groups, setGroups] = useState([]);

  // Obtener grupos al montar el componente y al cambiar la selección de grupo
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const userUrl = `${process.env.REACT_APP_ROOT_URL}/user/getAllUserEmails`;
        const response = await axios.get(userUrl);

        const groups = response.data.users;
        setGroups(groups);
        setSelectedGroup( null);
      } catch (error) {
        console.error('Error al obtener grupos:', error.message);
      }
    };

    fetchGroups();
  }, []); // Arreglo de dependencias vacío: se ejecuta solo una vez al montar

  // Función para obtener miembros de un grupo específico
  const fetchGroupMembers = async () => {
    if (selectedGroup) {
      setGroupMembers(selectedGroup.email || []);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchGroupMembers(selectedGroup);
    }
  }, [selectedGroup]);

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption.value);
  };

  const handleAddEmail = () => {
    // Valida la dirección de correo electrónico 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Si es incorrecta..
    if (!emailRegex.test(newEmail)) {
      // Tira un swal, borra el input y termina la funcion
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Por favor ingrese una direccion de correo valida.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
            confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
      setNewEmail('');
      return;
    }
  
    // Agrega el nuevo correo electrónico al array groupMembers
    setGroupMembers([...groupMembers, newEmail]);
    //// Tira un Swal de confirmacion
    //Swal.fire({
    //  icon: 'success',
    //  position: "center",
    //  text: `Correo agregado exitosamente.`,
    //  showConfirmButton: true,
    //  confirmButtonText: `OK`,
    //  customClass: {
    //      confirmButton: 'custom-button-confirmed',
    //  },
    //  timer: 2000,
    //});

    // Borra el input
    setNewEmail('');
  };

  const handleDeleteEmail = (emailToDelete) => {
    // Filtra los miembros del grupo para excluir el correo electrónico a eliminar
    const updatedGroupMembers = groupMembers.filter(email => email !== emailToDelete);
  
    // Actualiza el estado con la nueva lista de miembros
    setGroupMembers(updatedGroupMembers);
  };

  const handleSaveGroup = async () => {
    try { 
      selectedGroup.email = groupMembers;
      const submitUrl = `${process.env.REACT_APP_ROOT_URL}/campaigns/update-emails`;
      axios.post(submitUrl, {
        groupName: selectedGroup.groupName,
        email: selectedGroup.email
      })

      Swal.fire({
        icon: 'success',
        position: "center",
        text: `Grupo Guardado Correctamente!`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
            confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    } catch (error) {
      // Manejar errores de red u otras excepciones
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Error al guardar el grupo, por favor intente nuevamente más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
            confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    } 
  };

  const handleDeleteGroup = async () => {
    try {
      const submitUrl = `${process.env.REACT_APP_ROOT_URL}/campaigns/delete-group`;
      axios.post(submitUrl, {
        groupName: selectedGroup.groupName,
      })

      Swal.fire({
        icon: 'success',
        position: "center",
        text: `Grupo Eliminado Correctamente!`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
            confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Error al eliminar el grupo, por favor intente nuevamente más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
            confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    };
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <label htmlFor="group-select">Nombre del Grupo</label>
          <Select
            id='group-select'
            isMulti={false}
            options={groups.map((group) => ({
              value: group,
              label: group.groupName,
            }))}
            onChange={handleGroupChange}
            placeholder="Seleccionar un grupo"
            className="mb-3"
          />

          <label htmlFor="group-members">Miembros del grupo:</label>
          {groupMembers.length > 0 ? (
            <ol className="list-unstyled mb-3" id='group-members'>
              {groupMembers.map((member) => (
                <li key={member} className="d-flex justify-content-between">
                  <span>{member}</span>
                  <button
                    onClick={() => handleDeleteEmail(member)}
                    style={{
                      backgroundColor: '#f0320c',
                      color: 'white',
                      border: 'none',
                      borderRadius: '2px',
                      marginLeft: 'auto',
                    }}
                    className='mb-1'
                  >x</button>
                </li>
              ))}
            </ol>
          ) : (
            <p>Elija un grupo para mostrar sus miembros.</p>
          )}

          <label htmlFor="group-add">Agregar correos al grupo</label>
          <div className="d-flex align-items-center mb-3">
            <input
              id='group-add'
              type="text"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Nuevo correo"
              className="form-control flex-grow-1 mr-2"
            />
            <button 
            onClick={handleAddEmail} 
            style={{
              backgroundColor: '#27ab44',
              color: 'white',
              border: 'none',
              borderRadius: '2px'
            }}
            >+</button>
          </div>
          <button onClick={handleSaveGroup} className="btn mb-3">Guardar Grupo</button>
          <button onClick={handleDeleteGroup} className="btn mb-3 mx-3">Eliminar Grupo</button>
        </div>
      </div>
    </div>
  );
};

export default GroupViewer;