import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import CreateMain from './CreateMain';

// Image
import bannerbg from '../../../assets/img/banner02.png';
import Footer from "../../../components/MSFooter";

const Create = () => {
    return (
        <React.Fragment>
            <Header parentMenu='create'/>
            <SiteBreadcrumb pageTitle="Crear Curso" pageName="Códigos de Activación" titleClass="page-title white-color" breadcrumbsImg={bannerbg}/>
            <CreateMain/>
            <Footer/>
        </React.Fragment>
    );
};

export default Create;
