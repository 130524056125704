import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import bannerbg from '../../../assets/img/banner02.png';
import Footer from "../../../components/MSFooter";
import ReportByCourseMain from "./ReportByCourseMain";

const AdminReportCourses = () => {
    return (
        <React.Fragment>
            <Header parentMenu='create' />
            <SiteBreadcrumb
                pageTitle="Reporte Cursos"
                pageName="reporteCursos"
                titleClass="page-title white-color"
                breadcrumbsImg={bannerbg}
            />
            <ReportByCourseMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default AdminReportCourses;