import React from "react";
import ScrollToTop from "../../components/Common/ScrollTop";
import CoursePart from "./MyAccountSection";

const MyAccountMain = () => {
    return (
        <React.Fragment>
            <CoursePart />
            <ScrollToTop scrollClassName="scrollup orange-color"/>
        </React.Fragment>
    );
};

export default MyAccountMain;
