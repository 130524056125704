import React, {useState} from "react";
import Swal from "sweetalert2";
import Header from '../components/Layout/Header/Header';
import ScrollToTop from "../components/Common/ScrollTop";
import SiteBreadcrumb from '../components/Common/Breadcumb';
import bannerbg from '../assets/img/banner01.png';
import ButtonLoadingIndicator from "../components/Placeholder/ButtonLoadingIndicator";
import Footer from "../components/MSFooter";
import axios from "axios";

const initialRegisterData = {
    password: "",
    repassword: "",
}

const PasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const [registerData, setRegisterData] = useState(initialRegisterData);

    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setRegisterData((prevData) => ({...prevData, [id]: value}));
    };

    const resetPassword = async (e) => {
        setLoading(true);
        e.preventDefault();


        try {
            const token = window.location.pathname.split('/')[2]
            const password = document.getElementById('password').value;
            console.log(token)
            console.log(password)
            const requestBody = {
                token,
                password
            };

            const response = await axios.post(
                `${process.env.REACT_APP_ROOT_URL}/auth/resetPassword`,
                requestBody
            );

            if (response.status === 200) {
                Swal.fire({
                    position: "center",
                    title: "¡Contraseña reestablecida exitosamente!",
                    text: "Ya puede ingresar con normalidad.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                    timer: 2500,
                }).then(() => {
                    window.location.href = "/login";
                });
            } else {
                await Swal.fire({
                    title: "Error al reestablecer la contraseña",
                    text: "No se pudo reestablecer la contraseña. Por favor, inténtalo nuevamente más tarde.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                });
                console.error("Failed to update password");
            }
        } catch (error) {
            await Swal.fire({
                title: "Error al reestablecer la contraseña",
                text: "No se pudo reestablecer la contraseña. Por favor, inténtalo nuevamente más tarde.",
                showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
            });
            console.error("Error updating password: ", error);
        }
    };

    return (
        <React.Fragment>
            <Header parentMenu='create'/>

            <SiteBreadcrumb
                pageTitle="Reestablecer mi Contraseña"
                pageName="Reestablecer mi Contraseña"
                breadcrumbsImg={bannerbg}
            />
            <div className="back">
                <div className="register-section pt-100 pb-100 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="register-box">
                            <div className="styled-form">
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="#">
                                    <div className="row clearfix">
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="password"
                                                id="password"
                                                value={registerData.password || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Contraseña"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="password"
                                                id="repassword"
                                                value={registerData.repassword || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Confirmar Contraseña"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                                            {loading ? (
                                                <ButtonLoadingIndicator/>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="readon submit-btn"
                                                    onClick={(e) => resetPassword(e)}
                                                >
                                                    <span className="txt">Reestablecer Contraseña</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop scrollClassName="scrollup orange-color"/>
            <Footer/>
        </React.Fragment>
    );
};

export default PasswordReset;

