import React from "react";
// import { Link } from 'react-router-dom';

const Overview = ({data}) => {
    let name = "";
    let description = "";
    let category = [];
    let detail = "";
    if (data) {
        name = data.name;
        description = data.description;
        category = data.categories;
        detail = data.detail;
    }

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>{name}</h4>
                    <ul className="student-list">
                        {category.map((cat) => (
                            <li key={cat}>{cat}</li>
                        ))}
                    </ul>
                    <p className="mb-4">{description}</p>
                    <div dangerouslySetInnerHTML={{__html: detail}}/>
                </div>
            </div>
        </div>
    );
};

export default Overview;
