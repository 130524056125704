import React, { useState } from 'react';
import EmailLoader from './emailLoaderMain';
import bannerbg from '../../assets/img/banner01.png';
import Footer from "../../components/MSFooter";
import Header from "../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import GroupViewer from '../emailLoader/groupViewer';
import { Form } from 'react-bootstrap';


const Loader = () => {
    const [showEmailsMain, setShowEmailsMain] = useState(true);

    const toggleView = () => {
        setShowEmailsMain(!showEmailsMain);
    };

    return (
        <React.Fragment>
            <Header parentMenu='my-courses' />
            <SiteBreadcrumb
                pageTitle="Administrador de Grupos"
                pageName="Administrador de Grupos"
                breadcrumbsImg={bannerbg}
            />

            <div className="d-flex justify-content-end mx-3 mt-3">
                <Form.Check
                  type="switch"
                  id="toggle-view-switch"
                  label="Cambiar Vista"
                  checked={showEmailsMain}
                  onChange={toggleView}
                  className="custom-switch"
                />
            </div>

            {showEmailsMain ? (
              <EmailLoader/>
            ) : (
              <GroupViewer />
            )}
            
            <Footer/>
        </React.Fragment>
    );
}

export default Loader;