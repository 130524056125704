import React, {useEffect, useState} from "react";
import {Button} from 'react-bootstrap';
import Swal from "sweetalert2";
import CourseFlow from "./CourseFlow";
import ScrollToTop from "../../../components/Common/ScrollTop";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown'

const Main = ({ courseId }) => {

    //MODIFICAR
    const userId = useUserLocalStorage();
    const [courseData, setCourseData] = useState(null);
    const [notes, setNotes] = useState("");
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                if (!userId) return;

                const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/user/${userId}/courses/${courseId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch course data");
                }
                const data = await response.json();
                setCourseData(data);
                setNotes(data.notes);
                setIsFavorite(data.fav || false); 
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        if (courseId) {
            fetchCourseData();
        }
    }, [userId, courseId]);

    const handleNotesChange = (value) => {
        setNotes(value);
    };

    const handleSaveNotes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/user/${userId}/saveNotes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    courseId: courseId,
                    notes: notes,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save notes');
            }

            Swal.fire({
                position: "center",
                icon: "success",
                title: "¡Notas Guardadas con éxito!",
                text: `Las notas han sido guardadas exitosamente.`,
                showConfirmButton: false,
                timer: 1000,
            });
        } catch (error) {
            console.error('Error saving notes:', error);
            Swal.fire({
                title: "¡Ups! Algo salió mal",
                text: `Lo sentimos, no se pudieron guardar las notas en este momento. Por favor, inténtalo de nuevo más tarde`,
                icon: "error",
            });
        }
    };

    // Funcion para descargar las notas al sistema del usuario
    const handleDownloadNotes = async () => {
        const fileName = `notas-${courseId}-${Date.now()}.txt`;
        const blob = new Blob([NodeHtmlMarkdown.translate(notes)], { type: 'text/plain' });
        // Crear Url temp
        const url = window.URL.createObjectURL(blob);
        
        // Crea y clickea el link de descarga
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
    
        // Limpieza
        window.URL.revokeObjectURL(url);
    };

    const handleFavoriteToggle = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/user/${userId}/fav`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ courseId: courseId }),
            });

            if (!response.ok) throw new Error('Failed to save notes');

            setIsFavorite(!isFavorite);
            const message = `El curso se ha ${!isFavorite?"marcado":"desmarcado"} como favorito.`;

            Swal.fire({
                position: "center",
                icon: "success",
                title: "",
                text: message,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error saving notes:', error);
            Swal.fire({
                title: "Algo salió mal",
                text: `No se ha podido cambiar el flag de Favorito.`,
                icon: "error",
            });
        }
    };

    const modulo = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ]
    }

    return (
        <React.Fragment>
            <div className="backgroundPink rs-about style1 pt-50 pb-50 md-pt-50 md-pb-60" style={{ minHeight: "800px" }}>
                <div className="container backgroundWhite">
                    {courseData && (
                        <>
                            <div className="content-box pt-50">
                                <h2>
                                    {courseData.name} 
                                    <i 
                                        className={`fa ${isFavorite ? 'fa-heart' : 'fa-heart-o'} ms-3`} 
                                        style={{ cursor: 'pointer', color: '#4D61F4' }} 
                                        onClick={handleFavoriteToggle}
                                    ></i>
                                </h2>
                            </div>
                            {/*<div dangerouslySetInnerHTML={{ __html: courseData.detail }} />*/}
                            <CourseFlow courseData={courseData} courseId={courseId} notes={notes} />
                            <h2 className="pt-50">Mis notas:</h2>
                            <ReactQuill
                                theme="snow"
                                value={notes}
                                onChange={handleNotesChange}
                                modules={modulo}
                            />
                        </>
                    )}
                    <div className="pt-5">
                        <Button onClick={handleSaveNotes} className="btn-course">Guardar Notas</Button>
                        <Button onClick={handleDownloadNotes} className="btn-course mx-2">Descargar Notas</Button>
                    </div>
                </div>
            </div>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    );
};

export default Main;




