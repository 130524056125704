import React from 'react';
import ModifyCourseForm from '../../../components/ModifyCourse/ModifyCourseForm';

const Main = () => {
    return (
        <React.Fragment>
            <div className="rs-contact style1 create event-bg pt-0 md-pt-80 pb-50 md-pb-80">
                <div className="container contact-widget ">
                    <ModifyCourseForm/>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Main;