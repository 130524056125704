import React, {useState} from 'react';
import {v4} from "uuid";
import {imageStorage} from "../../../config/firebaseStorageConfig";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import '../../../assets/scss/admin/category/CreateCategoryModal.css';

const CreateCategoryModal = ({
                                 isOpen,
                                 onClose,
                                 selectedName,
                                 setSelectedName,
                                 selectedDescription,
                                 setSelectedDescription,
                                 selectedImage,
                                 setSelectedImage,
                                 onCreate
                             }) => {
    const [loading, setLoading] = useState(false);

    const handleUpload = async (e) => {
        setLoading(true);

        //upload image
        if (e.target.files) {
            const file = e.target.files[0];
            const images = ref(imageStorage, `categories/cat_${v4()}`);
            const fileUpload = await uploadBytes(images, file);
            const filePath = await getDownloadURL(fileUpload.ref);
            setSelectedImage(() => filePath);
        }
        setLoading(false);
    };

    const handleSave = (e) => {
        // e.preventDefault();
        console.log(`name: ${selectedName},
            description: ${selectedDescription},
            image: ${selectedImage},`);

        onCreate({
            name: selectedName,
            description: selectedDescription,
            image: selectedImage,
        });
    };

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} tabIndex="-1"
             style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Crear Categoría</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {loading && (
                            <div className="spinner-overlay">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        <div className={`content ${loading ? 'loading' : ''}`}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nombre:</label>
                                <input type="text" maxLength={20} className="form-control" value={selectedName}
                                       onChange={(e) => setSelectedName(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Descripción:</label>
                                <input type="text" maxLength={38} className="form-control" value={selectedDescription}
                                       onChange={(e) => setSelectedDescription(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Imagen (tamaño de imagen sugerido 250px x 200px):</label>
                                <input type="file" className="form-control"
                                       onChange={async (e) => await handleUpload(e)}/>
                            </div>
                            <div className="modal-footer">
                                <button id="cerrar-modal" type="button" className="btn-shop"
                                        onClick={onClose}>Cerrar
                                </button>
                                <button id="guardar-modal" type="button" className="btn-shop"
                                        onClick={handleSave}>Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCategoryModal;
