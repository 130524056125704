import React, {useEffect, useState} from 'react';
import useUserRoleLocalStorage from '../../components/UseUserRoleLocalStorage/UseUserRoleLocalStorage'; // Importar el hook

const Modal = ({ id, isOpen, onClose, selectedUser, handleRoleChange, selectedRole, handleSaveChanges }) => {
    const [getsNotifications, setGetsNotifications] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const userRole = useUserRoleLocalStorage(); // Obtener el rol del usuario utilizando el hook

    useEffect(() => {
        if (selectedUser) {
            setGetsNotifications(selectedUser.getsNotifications);
            setFirstName(selectedUser.firstName);
            setLastName(selectedUser.lastName);
        }
    }, [selectedUser]);

    const handleCheckboxChange = () => {
        setGetsNotifications(!getsNotifications);
    };

    const saveChanges = () => {
        handleSaveChanges(getsNotifications, firstName, lastName);
    };

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} id={id} tabIndex="-1" aria-labelledby={`${id}Label`}
             aria-hidden="true" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={`${id}Label`}>Editar Usuario</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">Nombre:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">Apellido:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <p>Email: {selectedUser && selectedUser.email}</p>
                        <label htmlFor="roleSelect" className="form-label">Rol:</label>
                        <select id="roleSelect" className="form-select" onChange={handleRoleChange}
                                value={selectedRole}>
                            {userRole === "superadmin" && <option value="superadmin">SuperAdmin</option>}
                            {(userRole === "admin" || userRole === "superadmin") && <option value="admin">Admin</option>}
                            <option value="user">User</option>
                        </select>
                        <div className="form-group mt-3">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={getsNotifications}
                                    onChange={handleCheckboxChange}
                                />
                                Recibir notificaciones
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn2 btn-delete me-2" onClick={onClose}>Cerrar</button>
                        <button type="button" className="btn2 btn-delete me-2" onClick={saveChanges}>Guardar Cambios</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
