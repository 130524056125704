import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import ReportByUserTable from './ReportByUserTable';

const ReportByUserMain = () => {

    return (
        <React.Fragment>
            <ReportByUserTable/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default ReportByUserMain;