// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCpEehDeqtShe78sgNS6haFqkEmORL-9vg",
    authDomain: "mindset-lab-4974a.firebaseapp.com",
    // databaseURL: "https://trade-learn-default-rtdb.firebaseio.com",
    projectId: "mindset-lab-4974a",
    storageBucket: "mindset-lab-4974a.appspot.com",
    messagingSenderId: "837123430090",
    appId: "1:837123430090:web:df8a6c57be974d465ac633",
    measurementId: "G-DGMVGJ2FC7"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const imageStorage = getStorage(app);