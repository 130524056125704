import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import Modal from "../../../components/Modal/Modal";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';

const AdminAllUsers = () => {
    ////////////////////////////
    // GET USERS DATA
    ////////////////////////////
    const [usersData, setUsersData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [loading, setLoading] = useState(true);

    const userId = useUserLocalStorage();

    useEffect(() => {
        if (userId) {
            showUsersData();
        }
    }, [userId]);

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const getUsersUrl = () => {
        if (userId) {
            return `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_USERS_GET_ALL.replace('<userId>', userId)}`;
        } else {
            return null;
        }
    };

    const usersActivateUrl = (userId) => {
        return `${process.env.REACT_APP_ROOT_URL}/user/${userId}/activate`;
    };

    const usersDeactivateUrl = (userId) => {
        return `${process.env.REACT_APP_ROOT_URL}/user/${userId}/deactivate`;
    };

    const showUsersData = async () => {
        try {
            const response = await fetch(
                getUsersUrl()
            );
            const data = await response.json();
            
            setUsersData(data);
            
        } catch (error) {
            console.error("Error fetching users data:", error);
        } finally {
            setLoading(false);
        }
    };

    const ExportCSV = ({ data }) => {
        const headers = [
          { label: 'Email', key: 'email' },
          { label: 'Apellido', key: 'lastName' },
          { label: 'Nombre', key: 'firstName' },
          { label: 'Código', key: 'activationCode' },
          { label: 'Rol', key: 'role' },
          { label: 'Alta', key: 'date' },
        ];

        return (
          <CSVLink data={usersData} headers={headers} filename={"Usuarios.csv"}>
            Descargar CSV
          </CSVLink>
        );
      };

    useEffect(() => {
        showUsersData();
    }, []);

    useEffect(() => {
        
    }, [usersData]);

    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////

    const columns = [
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            grow: 3,
            reorder: true,
        },
        {
            name: "Apellido",
            selector: (row) => row.lastName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Nombre",
            selector: (row) => row.firstName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Código",
            selector: (row) => row.activationCode,
            sortable: true,
            reorder: true,

        },
        {
            name: "Rol",
            selector: (row) => row.role,
            sortable: true,
            reorder: true,
        },
        {
            name: "Alta",
            selector: (row) => row.date,
            sortable: true,
            reorder: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="">
                    <button
                        className={`btn-status ${
                            row.active ? "btn-deactivate" : "btn-activate"
                        } me-2`}
                        onClick={() => handleActivateUser(row.active, row.id)}
                    >
                        {row.active ? (
                            <i className="toggle-off fa fa-power-off"></i>
                        ) : (
                            <i className="toggle-on fa fa-power-off"></i>
                        )}
                    </button>
                    <button
                        className="btn2 btn-modify me-2"
                        onClick={() => handleModify(row.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#modifyModal"
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            grow: 2,
        },
    ];

    ////////////////////////////
    // MANAGE ACTIVATION
    ////////////////////////////
    const handleActivateUser = async (userStatus, userId) => {
        
        const action = userStatus ? "Desactivar" : "Activar";

        Swal.fire({
            html: `<h6>¿Confirmas que deseas <em style="text-decoration: underline;">${action}</em> este usuario?</h6>`,
            padding: "3em 0em",
            showCancelButton: true,
            confirmButtonText: `Si, ${action}`,
            cancelButtonText: "No, Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleUserStatus(userStatus, userId);
            }
        });
    };

    const handleUserStatus = async (userStatus, userId) => {
        if (userStatus === true) {
            try {
                setLoading(true);
                const response = await fetch(
                    usersDeactivateUrl(userId),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    setLoading(false);
                    Swal.fire({
                        position: "center",
                        title: "El usuario ha sido rechazado.",
                        showConfirmButton: false,
                        padding: "3em",
                        timer: 3000,
                    }).then(() => {
                        window.location.href = "/admin/usuarios";
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo desactivar el usuario en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "¡Error de procesamiento de activacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        } else {
            try {
                setLoading(true);
                const response = await fetch(
                    usersActivateUrl(userId),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    setLoading(false);
                    Swal.fire({
                        position: "center",
                        title: "¡El usuario ha sido aprobado exitosamente!",
                        showConfirmButton: false,
                        padding: "3em",
                        timer: 3000,
                    }).then(() => {
                        window.location.href = "/admin/usuarios";
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo activar el usuario en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.log("Error al activar usuario:", error);
                Swal.fire({
                    title: "¡Error de procesamiento de activacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        }
    };

    ////////////////////////////
    // REDIRECT TO MODIFY
    ////////////////////////////
    const handleModify = (userId) => {
        const user = usersData.find(user => user.id === userId);
        setSelectedUser(user);
        setSelectedUserId(userId);
        setSelectedRole(user.role);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedUserId(null);
    };

    const handleSaveChanges = async (getsNotifications, firstName, lastName) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}/user/${selectedUserId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        role: selectedRole,
                        getsNotifications: getsNotifications,
                        firstName: firstName,
                        lastName: lastName,
                    }),
                }
            );

            if (response.ok) {
                handleModalClose();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Modificaciones guardadas con éxito!",
                    text: `Los cambios han sido guardados exitosamente.`,
                    showConfirmButton: false,
                    timer: 1000,
                }).then(() => {
                    window.location.href = "/admin/usuarios";
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al guardar los cambios",
                    text: "No se pudieron actualizar los datos. Por favor, inténtalo nuevamente más tarde.",
                });
                console.error("Failed to update user data");
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error de procesamiento",
                text: "Ocurrió un error al procesar la solicitud. Por favor, inténtalo nuevamente más tarde.",
            });
            console.error("Error saving changes:", error);
        }
    };

    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#0142FC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#f0f0f0",
            },
            action: {
                button: "#0142FC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Usuarios por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder />
            ) : (
                <div>
                    <ExportCSV data={usersData} />
                    <DataTable
                        columns={columns}
                        data={usersData}
                        noDataComponent="Cargando usuarios"
                        responsive={true}
                        theme={"tradeTheme"}
                        customStyles={customStyles}
                        style={{ fontSize: "18px" }}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>
            )}
            <Modal
                id="modifyModal"
                isOpen={modalOpen}
                onClose={handleModalClose}
                userId={selectedUserId}
                selectedUser={selectedUser}
                handleRoleChange={handleRoleChange}
                selectedRole={selectedRole}
                handleSaveChanges={handleSaveChanges}
            />
        </div>
    );
};

export default AdminAllUsers;