import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import ScrollToTop from "../components/Common/ScrollTop";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import axios from "axios";
import Swal from "sweetalert2";
import useUserStore from "../store/useUserStore";
import ButtonLoadingIndicator from "../components/Placeholder/ButtonLoadingIndicator";


// Image
import favIcon from "../assets/img/fav-orange.png";
import bannerbg from "../assets/img/banner01.png";
import Footer from "../components/MSFooter";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [redirectToHome, setRedirectToHome] = useState(false);
    const { isAuthenticated } = useUserStore();

    useEffect(() => {
        const token = localStorage.getItem('userProfile');
        if (token) {
            useUserStore.setState({ isAuthenticated: true });
            setRedirectToHome(true);
        }
    }, [isAuthenticated]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const requestBody = {
            email,
            password,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROOT_URL}/auth/login`,
                requestBody
            );

            if (response.status === 201) {
                // Detecta distintos tipos de problema incluso si la request funciono
                if (response.data.token === 'El usuario no está activo.' || response.data.token === 'No se pudo obtener el perfil del usuario por correo' || response.data.token === 'Usuario o contraseña inválidas.') {
                    throw new Error(response.data.token);
                }
                else {
                    // Guarda el token en LocalStorage
                    localStorage.setItem(
                    "userProfile",
                    JSON.stringify(response.data)
                    );
                }

                // Si no hay errores en la respuesta sigue con el proceso de aprobar el login
                useUserStore.setState({ isAuthenticated: true });
                Swal.fire({
                    position: "center",
                    title: `¡Bienvenido!`,
                    text: "¡Comienza a explorar tus cursos ahora mismo!",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                    timer: 2500,
                }).then(() => {
                    setRedirectToHome(true);
                });
            }
            // Nunca se va a ejecutar porque siempre que detecta cualquier tipo de falla axios tira un error 500, que lo va a atrapar el catch antes de ejecutar esto (creo) 
            else {
                Swal.fire({
                    title: "¡Ups! Algo salió mal",
                    text: "Lo sentimos, no se pudo loguear el usuario. Por favor, inténtalo de nuevo más tarde.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                });
            }
        // En caso de atrapar un error,
        } catch (error) {
            // Popup con mensaje especifico en caso de que el usuario no haya sido autorizado
            if (error.message === 'El usuario no está activo.'){
                Swal.fire({
                    text: "Usuario pendiente de aprobación.",
                    icon: "error",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                });
            }
            // En caso de otro error, generar popup con mensaje de error generico
            else {
                Swal.fire({
                    title: "¡Ups!",
                    text: "Correo Electrónico o Contraseña Incorrectos. Por favor intentalo nuevamente.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                });
            }
        } finally {
            setLoading(false);
        }
    };

    if (redirectToHome) {
        return <Redirect to="/" />;
    }


    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <Header parentMenu="login" />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Iniciar Sesión"
                pageName="Login"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* Login Part Start */}
            <div className="back">
                <div className="rs-login p-5 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="noticed">
                            <div className="main-part">
                                <div className="method-account">
                                    <form>
                                        <input
                                            type="email"
                                            name="E-mail"
                                            placeholder="Ingresa tu correo"
                                            value={email}
                                            onChange={handleEmailChange}
                                            required
                                        />
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Ingresa tu contraseña"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            required
                                        />

                                        {loading ? (
                                            <ButtonLoadingIndicator />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="readon submit-btn"
                                                onClick={handleSubmit}
                                            >
                                                Iniciar Sesión
                                            </button>
                                        )}

                                        <div className="users">
                                            
                                                <Link to="/forgotPassword">¿Olvidaste tu contraseña?</Link>
                                                </div>  
                                                <div className="users">
                                                <Link to="/register">Crear una cuenta</Link>
                                            
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop scrollClassName="scrollup orange-color" />
            <Footer />
        </React.Fragment>
    );
};

export default Login;
