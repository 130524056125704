import React, {useEffect, useState} from "react";
import axios from "axios";
import DataTable, {createTheme} from "react-data-table-component";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder";

const GroupsTable = () => {
    const [loading, setLoading] = useState(false)
    const [groups, setGroups] = useState([])

    useEffect(() => {
        getEmails();
    }, []);

    const getEmails = async () => {
        setLoading(true)
        try {
            const userEmails = await axios.get(`${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_GROUPS_ALL_EMAILS}`);
            setGroups(userEmails.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'Grupo',
            selector: row => row.key,
            grow: 5,
        },
        {
            name: 'Acciones',
            selector: row => {
                if (row.emails?.length > 0) {
                    const mailList = `mailto:?bcc=${row.emails}`;
                    return <a href={mailList}>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                    </a>
                }
            },
        },
    ];

    ////////////////////////////
    // SET DATATABLE THEME
    ////////////////////////////
    createTheme("tradeTheme", {
        text: {
            primary: "#333333",
            secondary: "#0142FC",
        },
        background: {
            default: "#f0f0f0",
        },
        context: {
            background: "#ffae42",
            text: "#000000",
        },
        divider: {
            default: "#f0f0f0",
        },
        action: {
            button: "#0142FC",
            hover: "#ff8c66",
            disabled: "#999999",
        },
    }, "light");

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder />
            ) : (
                <div>
                    <DataTable
                        columns={columns}
                        data={groups}
                        noDataComponent="No hay datos disponibles"
                        responsive={true}
                        theme={"tradeTheme"}
                        // customStyles={customStyles}
                        style={{ fontSize: "18px" }}
                    />
                </div>
            )}
        </div>
    );
};

export default GroupsTable;

