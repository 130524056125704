import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tabs } from "react-tabs";
import CourseSingleTwo from "../../components/Courses/CourseSingleMyAccount";
import CustomPlaceholder from "../../components/Placeholder/CustomPlaceHolder";
import useUserLocalStorage from "../../components/UseUserLocalStorage/UseUserLocalStorage";

import courseImg1 from "../../assets/img/courses/1.jpg";

const CoursePart = () => {
    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(true);

    //MODIFICAR
    const userId = useUserLocalStorage();
    const history = useHistory();

    const fetchDataMyCourses = async () => {
        try {
            const coursesResponse = await fetch(
                `${process.env.REACT_APP_ROOT_URL}/user/${userId}/courses`
            );
            const coursesData = await coursesResponse.json();
            setFetchData(coursesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchDataMyCourses();
        }
    }, [userId]);

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const handleCourseClick = (courseId) => {
        history.push(`/course-flow/${courseId}`);
    };

    const renderCoursePanel = () => {
        return (
            <div className="row">
                {Array.isArray(fetchData) ? (
                    fetchData.map((course) => (
                        <div key={course.id} className="col-lg-3 col-md-6" onClick={() => handleCourseClick(course.id)}>
                            <CourseSingleTwo
                                key={course.id}
                                courseClass="courses-item md-mb-30"
                                courseImg={course.image === "" ? courseImg1 : course.image}
                                courseTitle={truncateText(course.name, 20) || "Curso en desarrollo"}
                                courseDuration={course.duration}
                                courseId={course.id}
                                courseDetail={truncateText(course.description, 40)}
                                courseCategory={
                                    course.categories?.length > 0
                                        ? course.categories.join(", ")
                                        : ""
                                }
                                completed={course.completed}
                                fav={course.fav}
                            />
                        </div>
                    ))
                ) : (
                    <p>No courses found</p>
                )}
            </div>
        );
    };

    return (
        <div
            id="rs-popular-course"
            className="rs-popular-courses style1 orange-style pt-50 pb-100 md-pt-70 md-pb-50"
        >
            <div className="container">
                {loading ? (
                    <CustomPlaceholder/>
                ) : (
                    <Tabs>
                        <div>
                            {renderCoursePanel()}
                        </div>
                    </Tabs>
                )}
            </div>
        </div>
    );
};

export default CoursePart;
