import React, {useState} from 'react';
import Swal from "sweetalert2";
import ButtonLink from '../../../components/ButtonCreate/button.js';
import CreateCategoryModal from '../../../components/admin/category/ModalCreateCategory.js';

const CreateModal = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState('')
    const [newCategories, setNewCategories] = useState({
        name: '',
        description: '',
        image: '',
        active: false
    });

    const handleNewCategoriesChange = (e) => {
        const {name, value} = e.target;
        setNewCategories(prevState => ({
            ...prevState,
            [name]: value
        }), () => {

        });
    };


    const postCategoriesUrl = () => {
        return `${process.env.REACT_APP_ROOT_URL}/categories`;
    };


    const handleCreateNewCategories = (newCategories) => {
        //Create Category
        const url = postCategoriesUrl();
        if (url) {
            const dataToSend = {
                name: newCategories.name,
                description: newCategories.description,
                image: newCategories.image,
                active: false
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            })
                .then(response => {
                    if (response.ok) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "¡Categoría creada con éxito!",
                            text: `La categoría ha sido creada exitosamente.`,
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            window.location.href = "/admin/categories";
                        });
                    } else {
                        Swal.fire({
                            title: "¡Ups! Algo salió mal",
                            text: `Lo sentimos, no se pudo crear la categoría en este momento. Por favor, inténtalo de nuevo más tarde.`,
                            icon: "error",
                        });
                    }
                    return response.json();
                })
                .then(data => {

                })
                .catch(error => {
                    console.error('Error al crear la nueva categoría´:', error);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, ocurrió un error al crear la categoría. Por favor, inténtalo de nuevo más tarde.`,
                        icon: "error",
                    });
                });
        } else {
            console.error('No se pudo obtener la URL para la solicitud POST.');
            Swal.fire({
                title: "¡Ups! Algo salió mal",
                text: `No se pudo obtener la URL para la solicitud POST. Por favor, inténtalo de nuevo más tarde.`,
                icon: "error",
            });
        }
    };


    const handleButtonClick = () => {
        setModalOpen(true);
    };

    return (
        <React.Fragment>
            <ButtonLink to="#" onClick={handleButtonClick}>Crear nueva</ButtonLink>
            <CreateCategoryModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onCreate={handleCreateNewCategories}
                newCategories={newCategories}
                onChange={handleNewCategoriesChange}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                selectedDescription={selectedDescription}
                setSelectedDescription={setSelectedDescription}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
            />
        </React.Fragment>
    );
}

export default CreateModal;


