import React from 'react';
import MyAccountMain from './MyAccountMain';
import bannerbg from '../../assets/img/banner01.png';
import Footer from "../../components/MSFooter";
import Header from "../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const MyAccount = () => {

    return (
        <React.Fragment>
            <Header parentMenu='my-courses' />
            <SiteBreadcrumb
                pageTitle="Mi Cuenta"
                pageName="Cuenta"
                breadcrumbsImg={bannerbg}
            />
            <MyAccountMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default MyAccount;