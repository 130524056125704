import React, {useEffect, useState} from 'react';
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {imageStorage} from "../../../config/firebaseStorageConfig";
import {v4} from "uuid";

const EditCategoryModal = ({
                   id,
                   isOpen,
                   onClose,
                   selectedCategory,
                   handleSaveChanges
               }) => {
    const [editedName, setEditedName] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [editedImage, setEditedImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');


    useEffect(() => {
        if (selectedCategory) {
            setEditedName(selectedCategory.name);
            setEditedDescription(selectedCategory.description);
            setEditedImage(selectedCategory.image);
            setImagePreview(selectedCategory.image);
        }
    }, [selectedCategory]);

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setEditedDescription(e.target.value);
    };

    const handleUpload = async (e) => {
        setLoading(true);

        //upload image
        if (e.target.files) {
            const file = e.target.files[0];
            const images = ref(imageStorage, `categories/cat_${v4()}`);
            const fileUpload = await uploadBytes(images, file);
            const filePath = await getDownloadURL(fileUpload.ref);
            console.log(filePath);
            setEditedImage(() => filePath);

            // Set image preview
            setImagePreview(URL.createObjectURL(file));
        }
        setLoading(false);
    };

    const handleSave = () => {
        const updatedCategory = {
            ...selectedCategory,
            name: editedName,
            description: editedDescription,
            image: editedImage,
        };
        handleSaveChanges(updatedCategory);
    }; 

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} id={id} tabIndex="-1" aria-labelledby={`${id}Label`}
             aria-hidden="true" style={{display: isOpen ? 'block' : 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={`${id}Label`}>Editar Categoría</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {loading && (
                            <div className="spinner-overlay">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        <div className={`content ${loading ? 'loading' : ''}`}>
                            <div className="mb-3">
                                <label htmlFor="categoryName" className="form-label">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    value={editedName}
                                    onChange={handleNameChange}
                                    placeholder={selectedCategory ? selectedCategory.name : ''}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="categoryDescription" className="form-label">Descripción</label>
                                <textarea
                                    className="form-control"
                                    id="categoryDescription"
                                    value={editedDescription}
                                    onChange={handleDescriptionChange}
                                    placeholder={selectedCategory ? selectedCategory.description : ''}
                                />
                            </div>
                            
                            <div className="mb-3">
                            {imagePreview && (
                                <div className="image-container mt-3" style={{ position: "relative", display: "inline-block" }}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditedImage('');
                                            setImagePreview('');
                                        }}
                                        className="delete-button"
                                    >
                                        &times;
                                    </button>
                                    
                                    <img src={imagePreview} alt="Vista previa de la imagen" className="img-preview" style={{ maxWidth: "100%", borderRadius: "10px" }} />
                                </div>
                                )}
                                <label htmlFor="image" className="form-label">Imagen:</label>
                                <input type="file" className="form-control"
                                       onChange={async (e) => await handleUpload(e)}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn2 btn-delete me-2" onClick={onClose}>Cerrar</button>
                                <button type="button" className="btn2 btn-delete me-2" onClick={handleSave}>Guardar
                                    Cambios
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategoryModal;





