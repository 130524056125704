import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import bannerbg from '../../../assets/img/banner02.png';
import ReportByUserMain from './ReportByUserMain';
import Footer from "../../../components/MSFooter";

const AdminReportUsers = () => {
    return (
        <React.Fragment>
            <Header parentMenu='admin-users' />
            <SiteBreadcrumb
                pageTitle="Reporte Usuarios"
                pageName="reporteUsuarios"
                titleClass="page-title white-color"
                breadcrumbsImg={bannerbg}
            />
            <ReportByUserMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default AdminReportUsers;