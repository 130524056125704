import React, {useEffect, useState} from "react";
import ModalVideo from "react-modal-video";
import {Link} from "react-router-dom";
import ButtonLink from "../../../components/ButtonCourse/button";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import {useHistory, useParams} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Swal from "sweetalert2";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder";

// Image
import videoImg from "../../../assets/img/about/about-video-bg2.png";

const CourseSidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true); // Estado para la carga específica de verificación de inscripción
    const [isEnrolled, setIsEnrolled] = useState(false); // Estado para verificar si el usuario ya está inscrito
    const [courseData, setCourseData] = useState(null); // Estado para almacenar los datos del curso
    const openModal = () => setIsOpen(!isOpen);

    const {id} = useParams();

    //MODIFICAR
    const userId = useUserLocalStorage();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ROOT_URL}/courses/${id}`);
                const data = response.data;
                setCourseData(data);
                setLoading(false); // Desactivar loading una vez que se obtienen los datos
            } catch (error) {
                console.error("Error fetching course data:", error);
                setLoading(false); // Desactivar loading en caso de error
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (courseData) {
            // Verificar si el usuario está inscrito en el curso
            if (courseData.enrolledUsers.includes(userId)) {
                setIsEnrolled(true);
            }
        }
    }, [courseData, userId]);

    const enrollCourse = async () => {
        try {
            setLoading(true); // Activar loading

            // Verifica si el usuario ya está inscrito en el curso.
            if (isEnrolled) {
                setLoading(false); // Desactivar loading
                return;
            }

            await axios.post(`${process.env.REACT_APP_ROOT_URL}/courses/enroll`, {
                userId: userId,
                id: id,
            });

            // inscripción exitosa.
            Swal.fire({
                icon: "success",
                title: "¡Felicitaciones!",
                text: "Te has inscripto en el curso correctamente.",
            });
            setLoading(false); // Desactivar loading

            // Redirección al curso
            history.push(`/course-flow/${id}`);

        } catch (error) {
            console.error("Error en la solicitud POST:", error);
            setLoading(false); // Desactivar loading

            // fallo en la solicitud
            Swal.fire({
                icon: "error",
                title: "¡Error!",
                text: "Hubo un error al inscribirse en el curso. Por favor, inténtalo de nuevo más tarde.",
            });
        }
    };

    return (
        <div className="inner-column">
            {/* <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="YLN1Argi7ik"
                onClose={() => {
                    openModal();
                }}
            />
            <div className="intro-video media-icon orange-color2">
                <img className="video-img" src={videoImg} alt="media"/>
                <Link
                    to="#"
                    className="popup-videos"
                    onClick={() => {
                        openModal();
                    }}
                >
                    <i className="fa fa-play"></i>
                </Link>
                <h4>Vista previa del curso</h4>
            </div> */}
            {loading ? (
                <CustomPlaceholder/>
            ) : (
                <>
                    <div className="img-detail-course">
                        <img className="crop" src={courseData.image}/>
                    </div>
                    <div className="course-features-info">
                        <ul>
                            <li className="duration-feature">
                                <i className="fa fa-star"></i>
                                <span className="label">Categorías</span>
                                <span className="value">{courseData.categories}</span>
                            </li>
                            <li className="lectures-feature">
                                <i className="fa fa-files-o"></i>
                                <span className="label">Módulos</span>
                                <span className="value">{courseData.modules.length}</span>
                            </li>
                            <li className="duration-feature">
                                <i className="fa fa-clock-o"></i>
                                <span className="label">Duración</span>
                                <span className="value">{courseData.duration} horas</span>
                            </li>
                            <li className="students-feature">
                                <i className="fa fa-file-text-o"></i>
                                <span className="label">Alumnos Inscriptos</span>
                                <span className="value">{courseData.enrolledUsers.length} alumnos</span>
                            </li>
                        </ul>
                    </div>
                    <div className="text-center">
                        {isEnrolled ? (
                            <ButtonLink to={`/course-flow/${id}`}>
                                Ya estás inscripto
                            </ButtonLink>
                        ) : (
                            <ButtonLink to="#" onClick={enrollCourse}>
                                Empezar Curso
                            </ButtonLink>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default CourseSidebar;
