import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import ReportByCourse from './ReportByCourseTable';

const ReportByCourseMain = () => {

    return (
        <React.Fragment>
            <ReportByCourse/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default ReportByCourseMain;