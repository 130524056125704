import React from 'react';
import {Link} from 'react-router-dom';

import defaultLogo from '../../assets/img/footer-logo-white.png';
import defaultBG from '../../assets/img/footer.jpg';

const Footer = (props) => {
    const {footerClass, footerLogo, footerBG, footerColor} = props;

    const footerBGStyle = {
        backgroundImage: `url(${footerBG})`,
        backgroundColor: `url(${footerColor})`
    }
    const defaultBGStyle = {
        backgroundImage: `url(${defaultBG})`,
        backgroundColor: '#273c66'
    }


    return (
        <footer id="rs-footer" className={footerClass ? footerClass : 'rs-footer'}
                style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 md-mb-20">
                            <div className="footer-logo">
                                <Link to="/" as="/">
                                    <img src={footerLogo ? footerLogo : defaultLogo} alt="Logo"/>
                                </Link>
                            </div>
                            <div>
                                <p className="footer-p">La plataforma de formación online que necesitas para impulsar el crecimiento de tu negocio.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 md-mb-20">
                            <h4 className="widget-title">Contacto +</h4>
                            <ul className="address-widget address2">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">Buenos Aires, Argentina</div>
                                </li>
                                <li>
                                    <i className="fa fa-whatsapp fa-lg"></i>
                                    <div className="desc">
                                        <a href="https://api.whatsapp.com/send?phone=+5491126117588&text=%C2%A1Hola!%20estoy%20en%20Mindset%20Lab%20y%20quisiera%20consultar%20acerca%20de..."
                                           target="_blank">+54 9 11 2 611 7588</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <a href="mailto:hola@mindset-lab.com">hola@mindset-lab.com</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 md-mb-20">
                            <h4 className="widget-title">Seguinos +</h4>
                            <ul className="footer-social">
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=+5491126117588&text=%C2%A1Hola!%20estoy%20en%20Mindset%20Lab%20y%20quisiera%20consultar%20acerca%20de..." target="_blank" rel="noreferrer"><i className="fa fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=+5491126117588&text=%C2%A1Hola!%20estoy%20en%20Mindset%20Lab%20y%20quisiera%20consultar%20acerca%20de..." target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mindsetlab.app/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;