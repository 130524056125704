import React from "react";
// import { Link } from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import useDataStore from "../../../store/store";
import Overview from "./overview";

const CourseDetailsTab = () => {
    const data = useDataStore((state) => state.data)
    let tab1 = "Detalle del curso";
    const tabStyle = "intro-tabs tabs-box";

    return (
        <div className="intro-info-tabs">
            <Tabs>
                <TabList className={tabStyle}>
                    <Tab>
                        <button>{tab1}</button>
                    </Tab>
                </TabList>
                <TabPanel>
                    <Overview data={data}/>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default CourseDetailsTab;

