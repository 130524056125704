import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';

const AdminAllCourses = () => {

    ////////////////////////////
    // GET COURSES DATA
    ////////////////////////////
    const [coursesData, setCoursesData] = useState([]);
    const [loading, setLoading] = useState(true);

    const showCoursesData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_GET_ALL}`
            );
            const data = await response.json();
            setCoursesData(data);
        } catch (error) {
            console.error("Error fetching users data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        showCoursesData();
    }, []);

    const ExportCSV = ({ data }) => {
        const headers = [
          { label: 'Nombre', key: 'name' },
          { label: 'Módulos', key: 'modulesCount' },
          { label: 'Categorias', key: 'categories' }
        ];

        return (
          <CSVLink data={coursesData} headers={headers} filename={"Cursos.csv"}>
            Descargar CSV
          </CSVLink>
        );
    };

    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////
    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
        },
        {
            name: "Módulos",
            selector: (row) => row.modulesCount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Categorias",
            selector: (row) => `${row.categories}`,
            sortable: true,
            reorder: true,
        },
        {
            name: "Estado",
            selector: (row) => (row.active ? "Activo" : "Inactivo"),
            sortable: true,
            reorder: true,
            conditionalCellStyles: [
                {
                    when: (row) => !row.active,
                    style: {
                        color: "red",
                    },
                },
                {
                    when: (row) => row.active,
                    style: {
                        color: "green",
                    },
                },
            ],
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div>
                    <button
                        className={`btn-status ${
                            row.active ? "btn-deactivate" : "btn-activate"
                        } me-2`}
                        onClick={() => handleActivateCourse(row.active, row.id)}
                    >
                        {row.active ? (
                            <i className="toggle-off fa fa-power-off"></i>
                        ) : (
                            <i className="toggle-on fa fa-power-off"></i>
                        )}
                    </button>
                    <button
                        className="btn2 btn-modify me-2"
                        onClick={() => handleModify(row.id)}
                    >
                        <i className="fa fa-edit"></i>
                    </button>

                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#0142FC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#f0f0f0",
            },
            action: {
                button: "#0142FC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    ////////////////////////////
    // MANAGE ACTIVATION
    ////////////////////////////
    const handleActivateCourse = async (courseStatus, courseId) => {
        const action = courseStatus ? "Desactivar" : "Activar";

        Swal.fire({
            html: `<h6>¿Confirmas que deseas <em style="text-decoration: underline;">${action}</em> este curso?</h6>`,
            padding: "3em 0em",
            showCancelButton: true,
            confirmButtonText: `Si, ${action}`,
            cancelButtonText: "No, Cancel",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleCourseStatus(courseStatus, courseId);
            }
        });
    };

    const handleCourseStatus = async (courseStatus, courseId) => {

        if (courseStatus === true) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_DEACTIVATE}/${courseId}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "¡Curso desactivado con éxito!",
                        text: `El curso ha sido desactivado exitosamente.`,
                        showConfirmButton: false,
                        timer: 1000,
                    }).then(() => {
                        window.location.href = "/admin/cursos";
                    });
                } else {
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo desactivar el curso en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "¡Error de procesamiento de activacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        } else {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_ACTIVATE}/${courseId}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "¡Curso activado con éxito!",
                        text: `El curso ha sido activado exitosamente.`,
                        showConfirmButton: false,
                        timer: 2500,
                    }).then(() => {
                        window.location.href = "/admin/cursos";
                    });
                } else {
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo activar el curso en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: "¡Error de procesamiento de desactivacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        }
    };

    ////////////////////////////
    // COURSE DELETE
    ////////////////////////////


    ////////////////////////////
    // REDIRECT TO MODIFY
    ////////////////////////////
    const handleModify = (id) => {
        window.location.href = `/modify/${id}`;
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder/>
            ) : (
                <div>
                <ExportCSV data={coursesData} />
                <DataTable
                    columns={columns}
                    data={coursesData}
                    noDataComponent="Cargando..."
                    pagination
                    theme={"tradeTheme"}
                    customStyles={customStyles}
                    responsive={true}
                    style={{fontSize: "18px"}}
                    paginationComponentOptions={paginationComponentOptions}
                />
                </div>
            )}
        </div>
    );
};
export default AdminAllCourses;
