import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import Papa from 'papaparse'
import axios from 'axios';
import Swal from 'sweetalert2';

const EmailLoader = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [manualEmails, setManualEmails] = useState([]);
  const [groupName, setGroupName] = useState('');

  const handleCsvChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleManualEmailChange = (event) => {
    setManualEmails(event.target.value.split(',').map((email) => email.trim()));
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  // useEffect hook para analizar datos del CSV al cambiar el archivo
  useEffect(() => {
    const parseCsvData = async () => {
      if (!csvFile) return;

      try {
        const csvDataPromise = new Promise((resolve, reject) => {
          Papa.parse(csvFile, { header: false, complete: resolve, error: reject });
        });

        const csvData = await csvDataPromise;
        const extractedEmails = csvData.data.map((row) => row[0]);
        // Actualizar estado con correos extraídos
        setManualEmails([...manualEmails, ...extractedEmails]); 
      } catch (error) {
        console.error('Error al analizar CSV:', error);
        // Manejar errores de análisis
      }
    };

    // Parsear cvs
    parseCsvData();
  }, [csvFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/campaigns/load-emails`;
      const response = await axios.post(url, {
        groupName: groupName,
        email: manualEmails,
      });

      if (response.status === 200 || response.status === 201) {
        // Manejar creación exitosa del grupo
        Swal.fire({
          icon: 'success',
          position: "center",
          text: `Grupo Creado Correctamente!`,
          showConfirmButton: true,
          confirmButtonText: `OK`,
          customClass: {
            confirmButton: 'custom-button-confirmed',
          },
          timer: 4000,
        });
      } else {
        throw new Error('respuesta del servidor: '+response);
      }
    } catch (error) {
      // Manejar errores de red u otras excepciones
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Error al crear el grupo, por favor intente nuevamente más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
          confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 my-3">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="groupName">Nombre del Grupo</label>
                <input type="text" className="form-control" id="groupName" value={groupName} onChange={handleGroupNameChange} placeholder="Ingrese el nombre del grupo" required/>
              </div>
              <div className="form-group">
                <label htmlFor="csvFile">Cargar archivo CSV</label>
                <input type="file" className="form-control" id="csvFile" onChange={handleCsvChange} />
              </div>
              <div className="form-group">
                <label htmlFor="manualEmails">Ingresar correos manualmente</label>
                <input type="text" className="form-control" id="manualEmails" value={manualEmails.join(',')} onChange={handleManualEmailChange} placeholder="Ingrese correos separados por comas" />
              </div>
              <button type="submit" className="btn btn-primary">Crear Grupo</button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailLoader;