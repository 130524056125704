import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Header from '../components/Layout/Header/Header';
import ScrollToTop from "../components/Common/ScrollTop";
import SiteBreadcrumb from '../components/Common/Breadcumb';
import bannerbg from '../assets/img/banner02.png';
import ButtonLoadingIndicator from "../components/Placeholder/ButtonLoadingIndicator";
import Footer from "../components/MSFooter";

const initialRegisterData = {
    firstName: "",
    lastName: "",
    email: "",
    salt: "",
    activationCode: "",
    password: "",
    repassword: "",
}

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [registerData, setRegisterData] = useState(initialRegisterData);
    const history = useHistory();

    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setRegisterData((prevData) => ({...prevData, [id]: value}));
    };

    const registerAction = async (e) => {
        setLoading(true);
        e.preventDefault();

        const newUser = {
            email: registerData.email,
            firstName: registerData.firstName,
            lastName: registerData.lastName,
            password: registerData.password,
            activationCode: registerData.activationCode,
            role: "user",
            getsNotifications: false,
            active: false
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROOT_URL}/user`,
                newUser,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("Respuesta del servidor:", response); // Verificar la respuesta del servidor

            if (response.status === 201) {
                Swal.fire({
                    title: "¡Felicitaciones!",
                    text: "Tu usuario fue creado exitosamente.",
                    icon: "success",
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                }).then(() => {
                    history.push("/login");
                });
            } else {
                Swal.fire({
                    title: "¡Error!",
                    text: "Falló la creación del usuario",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error al crear el usuario:", error); // Verificar errores
            Swal.fire({
                title: "¡Error!",
                text: "Ocurrió un error al crear el usuario",
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Header parentMenu='create'/>
            <SiteBreadcrumb pageTitle="¡Registrate ahora!" pageName="Register" titleClass="page-title white-color" breadcrumbsImg={bannerbg} />
            <div className="back">
                <div className="register-section pt-50 pb-50 md-pt-50 md-pb-50">
                    <div className="container">
                        <div className="register-box">
                            <div className="styled-form">
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="#">
                                    <div className="row clearfix">
                                        <div className="form-group col-lg-12 mb-25">
                                            <input
                                                type="text"
                                                id="firstName"
                                                value={registerData.firstName || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Nombre"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="text"
                                                id="lastName"
                                                value={registerData.lastName || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Apellido"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="email"
                                                id="email"
                                                value={registerData.email || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Direccion de email"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="text"
                                                id="activationCode"
                                                value={registerData.activationCode || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Código de activación"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="password"
                                                id="password"
                                                value={registerData.password || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Contraseña"
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <input
                                                type="password"
                                                id="repassword"
                                                value={registerData.repassword || ""}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Confirmar Contraseña"
                                                required
                                            />
                                        </div>
                                        <div className="form-group pt-50 col-lg-12 col-md-12 col-sm-12 text-center">
                                            {loading ? (
                                                <ButtonLoadingIndicator/>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="readon submit-btn"
                                                    onClick={(e) => registerAction(e)}
                                                >
                                                    <span className="txt">Registrarme</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <div className="users">
                                                ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop scrollClassName="scrollup orange-color"/>
            <Footer/>
        </React.Fragment>
    );
};

export default Register;

