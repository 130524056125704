import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import EditCategoryModal from "../../../components/admin/category/EditCategoryModal";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder";
import {CSVLink} from "react-csv";
import dotenv from "dotenv";

dotenv.config();

const AdminAllCategories = () => {
    ////////////////////////////
    // GET CATEGORIES DATA
    ////////////////////////////
    const [categoriesData, setCategoriesData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCategoriesId, setSelectedCategoriesId] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [loading, setLoading] = useState(true);

    const getCategoriesUrl = () => `${process.env.REACT_APP_ROOT_URL}/categories`;

    const categoriesActivateUrl = (categoriesId) => `${process.env.REACT_APP_ROOT_URL}/categories/${categoriesId}/activate`;

    const categoriesDeactivateUrl = (categoriesId) => `${process.env.REACT_APP_ROOT_URL}/categories/${categoriesId}/deactivate`;

    const showCategoriesData = async () => {
        try {
            const response = await fetch(getCategoriesUrl());
            const data = await response.json();
            setCategoriesData(data);
        } catch (error) {
            console.error("Error fetching users data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        showCategoriesData();
    }, []);

    const ExportCSV = ({ data }) => {
        const headers = [
            { label: 'Nombre', key: 'name' },
            { label: 'Descripción', key: 'description' },
            { label: 'Estado', key: 'state' }
        ];

        return (
            <CSVLink data={data} headers={headers} filename={"Categorias.csv"}>
                Descargar CSV
            </CSVLink>
        );
    };

    ////////////////////////////
    // SET DATATABLE COLUMNS
    ////////////////////////////

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
        },
        {
            name: "Descripción",
            selector: (row) => row.description,
            sortable: true,
            reorder: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="">
                    <button
                        className={`btn-status ${row.active ? "btn-deactivate" : "btn-activate"} me-2`}
                        onClick={() => handleActivateCategories(row.active, row.id)}
                    >
                        {row.active ? <i className="toggle-off fa fa-power-off"></i> : <i className="toggle-on fa fa-power-off"></i>}
                    </button>
                    <button
                        className="btn2 btn-modify me-2"
                        onClick={() => handleModify(row.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#modifyModal"
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    ////////////////////////////
    // MANAGE ACTIVATION
    ////////////////////////////
    const handleActivateCategories = async (categoriesStatus, categoriesId) => {
        const action = categoriesStatus ? "Desactivar" : "Activar";

        Swal.fire({
            html: `<h6>¿Confirmas que deseas <em style="text-decoration: underline;">${action}</em> esta categoría?</h6>`,
            padding: "3em 0em",
            showCancelButton: true,
            confirmButtonText: `Si, ${action}`,
            cancelButtonText: "No, Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleCategoriesStatus(categoriesStatus, categoriesId);
            }
        });
    };

    const handleCategoriesStatus = async (categoriesStatus, categoriesId) => {
        const url = categoriesStatus ? categoriesDeactivateUrl(categoriesId) : categoriesActivateUrl(categoriesId);

        try {
            setLoading(true);
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                setLoading(false);
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `¡Categoría ${categoriesStatus ? 'desactivada' : 'activada'} con éxito!`,
                    showConfirmButton: false,
                    timer: 1000,
                }).then(() => {
                    window.location.href = "/admin/categories";
                });
            } else {
                throw new Error("Failed to update category status");
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            Swal.fire({
                title: "¡Ups! Algo salió mal",
                text: `Lo sentimos, no se pudo ${categoriesStatus ? 'desactivar' : 'activar'} la categoría en este momento. Por favor, inténtalo de nuevo más tarde`,
                icon: "error",
            });
        }
    };

    ////////////////////////////
    // MODIFY
    ////////////////////////////
    const handleModify = (categoriesId) => {
        const categories = categoriesData.find((category) => category.id === categoriesId);
        setSelectedCategories(categories);
        setSelectedCategoriesId(categoriesId);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedCategoriesId(null);
    };

    const handleSaveChanges = async (updatedCategory) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/categories/${selectedCategoriesId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedCategory),
            });

            if (response.ok) {
                handleModalClose();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Categoría modificada con éxito!",
                    showConfirmButton: false,
                    timer: 1000,
                }).then(() => {
                    window.location.href = "/admin/categories";
                });
            } else {
                throw new Error("Failed to update category");
            }
        } catch (error) {
            console.error("Error updating category:", error);
            Swal.fire({
                icon: "error",
                title: "Error al modificar la categoría",
                text: "No se pudo actualizar la categoría. Por favor, inténtalo nuevamente más tarde.",
            });
        }
    };

    ////////////////////////////
    // SET DATATABLE THEME
    ////////////////////////////
    createTheme("tradeTheme", {
        text: {
            primary: "#333333",
            secondary: "#0142FC",
        },
        background: {
            default: "#f0f0f0",
        },
        context: {
            background: "#ffae42",
            text: "#000000",
        },
        divider: {
            default: "#f0f0f0",
        },
        action: {
            button: "#0142FC",
            hover: "#ff8c66",
            disabled: "#999999",
        },
    }, "light");

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder />
            ) : (
                <div>
                    <ExportCSV data={categoriesData} />
                    <DataTable
                        columns={columns}
                        data={categoriesData}
                        noDataComponent="Cargando..."
                        responsive={true}
                        theme={"tradeTheme"}
                        customStyles={customStyles}
                        style={{ fontSize: "18px" }}
                    />
                </div>
            )}
            <EditCategoryModal
                id="modifyModal"
                isOpen={modalOpen}
                onClose={handleModalClose}
                selectedCategory={selectedCategories}
                handleSaveChanges={handleSaveChanges}
            />
        </div>
    );
};

export default AdminAllCategories;
