import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/Layout/Header/Header';
import Footer from '../../../components/MSFooter';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import Main from './main';
import bannerbg from '../../../assets/img/breadcrumbs/inner7.jpg';


const CourseFlow = () => {
    const {id} = useParams();

    return (
        <React.Fragment>
            <Header parentMenu='pages' />
            <Main courseId={id} />
            <Footer />
        </React.Fragment>
    );
}

export default CourseFlow;

