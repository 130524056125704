import React from "react";
import CoursePart from "./table";
import ScrollToTop from "../../../components/Common/ScrollTop";
import GroupsTable from "./table";

const Main = () => {
    return (
        <React.Fragment>
            <GroupsTable/>
            <ScrollToTop scrollClassName="scrollup orange-color"/>
        </React.Fragment>
    );
};

export default Main;
