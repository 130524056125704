import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';


const AdminAllRequest = () => {
    ////////////////////////////
    // GET USERS DATA
    ////////////////////////////
    const [requestData, setRequestData] = useState([]);
    const [loading, setLoading] = useState(true);

    //MODIFICAR
    const userId = useUserLocalStorage();

    useEffect(() => {
        if (userId) {
            showRequestData();
        }
    }, [userId]);

    const getRequestUrl = () => {
        if (userId) {
            return `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_REQUESTS_GET_ALL.replace('<userId>', userId)}`;
        } else {
            // Manejo si userId es nulo
            return null;
        }
    };

    const approveRequestUrl = (userId, requestId) => {
        return `${process.env.REACT_APP_ROOT_URL}/requests/${userId}/${requestId}/approve`;
    };

    const rejectRequestUrl = (userId, requestId) => {
        return `${process.env.REACT_APP_ROOT_URL}/requests/${userId}/${requestId}/reject`;
    };


    const showRequestData = async () => {
        try {
            const response = await fetch(
                getRequestUrl()
            );
            const data = await response.json();
            setRequestData(data);
        } catch (error) {
            console.error("Error fetching request data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        showRequestData();
    }, []);


    useEffect(() => {
    }, [requestData]);

    const formatDateForDisplay = (dateString) => {
        if (!dateString) {
            return "";
        }

        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
    };

    const ExportCSV = ({ data }) => {
        const headers = [
          { label: 'Email', key: 'user.email' },
          { label: 'Apellido', key: 'user.lastName' },
          { label: 'Nombre', key: 'user.firstName' },
          { label: 'Registro', key: 'date' },
          { label: 'Código de Activación', key: 'user.activationCode' },
          { label: 'Rol', key: 'user.role' },
          { label: 'Estado', key: 'status' }
        ];

        return (
          <CSVLink data={requestData} headers={headers} filename={"Solicitudes de Acceso.csv"}>
            Descargar CSV
          </CSVLink>
        );
    };

    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////
    const columns = [
        {
            name: "Apellido",
            selector: (row) => row.user.lastName,
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Nombre",
            selector: (row) => row.user.firstName,
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Email",
            selector: (row) => row.user.email,
            sortable: true,
            grow: 3,
            reorder: true,
        },
        {
            name: "Fecha",
            selector: (row) => formatDateForDisplay(row.date),
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Código",
            selector: (row) => row.user.activationCode,
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Rol",
            selector: (row) => row.user.role,
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Estado",
            selector: (row) => row.status,
            sortable: true,
            grow: 1,
            reorder: true,
        },
        {
            name: "Acciones",
            grow: 2,
            cell: (row) =>(
                
                    <div className="">
                        <button
                            className={"btn-status btn-activate me-2"}
                            onClick={() => handleApproveRequest(row.id)}
                        >
                            <i className="toggle-on fa fa-check"></i>
                        </button>
                        <button
                            className={"btn-status btn-deactivate me-2"}
                            onClick={() => handleRejectRequest(row.id)}
                        >
                            <i className="toggle-off fa fa-times"></i>
                        </button>
                    </div>
                ),
            ignoreRowClick: true,
        },
    ];

    ////////////////////////////
    // MANAGE APPROVALS
    ////////////////////////////
    const handleApproveRequest = async (requestId) => {
        const confirmed = await confirmAction("Aprobar");
        if (!confirmed) return;

        try {
            setLoading(true);
            const response = await fetch(approveRequestUrl(userId, requestId), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                setLoading(false);
                Swal.fire({
                    position: "center",
                    title: "¡El usuario ha sido aprobado exitosamente!",
                    padding: "3em",
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    window.location.href = "/admin/request";
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "¡Ups! Algo salió mal",
                    text: `Lo sentimos, no se pudo aprobar el usuario en este momento. Por favor, inténtalo de nuevo más tarde`,
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error approving request:", error);
        }
    };

    const handleRejectRequest = async (requestId) => {
        const confirmed = await confirmAction("Rechazar");
        if (!confirmed) return;

        try {
            const response = await fetch(rejectRequestUrl(userId, requestId), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                Swal.fire({
                    position: "center",
                    title: "¡Usuario rechazado con éxito!",
                    padding: "3em",
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    window.location.href = "/admin/request";
                });
            } else {
                Swal.fire({
                    title: "¡Ups! Algo salió mal",
                    text: `Lo sentimos, no se pudo rechazar el usuario en este momento. Por favor, inténtalo de nuevo más tarde`,
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error rejecting request:", error);
        }
    };

    const confirmAction = async (action) => {
        return Swal.fire({
            html: `<h6>¿Confirmas que deseas <em style="text-decoration: underline;">${action}</em> a este usuario?</h6>`,
            padding: "3em 0em",
            showCancelButton: true,
            confirmButtonText: `Si, ${action}`,
            cancelButtonText: "No, Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
              },
        }).then((result) => {
            return result.isConfirmed;
        });
    };


    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#0142FC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#f0f0f0",
            },
            action: {
                button: "#0142FC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Solicitudes por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder/>
            ) : (
                <div>
                <ExportCSV data={requestData} />
                <DataTable
                    columns={columns}
                    data={requestData}
                    noDataComponent="Cargando solicitudes"
                    responsive={true}
                    theme={"tradeTheme"}
                    customStyles={customStyles}
                    style={{fontSize: "14px"}}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                />
                </div>
            )}
        </div>
    );
};

export default AdminAllRequest;
