import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import DataTable, {createTheme} from "react-data-table-component";
import Modal from "../../../components/ModalModifyCode/Modal.js";
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';
import dotenv from 'dotenv'

dotenv.config()

const AdminAllActivationCodes = () => {
    ////////////////////////////
    // GET ACTIVATION CODES DATA
    ////////////////////////////
    const [codesData, setCodesData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCodeId, setSelectedCodeId] = useState(null);
    const [selectedCode, setSelectedCode] = useState(null);
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [selectedDueDateObject, setSelectedDueDateObject] = useState(null);
    const [selectedDueDate, setSelectedDueDate] = useState(null);
    const [loading, setLoading] = useState(true);

    //MODIFICAR
    const userId = useUserLocalStorage();

    useEffect(() => {
        if (userId) {
            showCodesData();
        }
    }, [userId]);

    const getCodesUrl = () => {
        if (userId) {

            return `${process.env.REACT_APP_ROOT_URL}/activationcodes/${userId}`;
        } else {
            return null;
        }
    };

    const codeActivateUrl = (userId, codeId) => {
        return `${process.env.REACT_APP_ROOT_URL}/activationcodes/${userId}/${codeId}/activate`;
    };

    const codeDeactivateUrl = (userId, codeId) => {
        return `${process.env.REACT_APP_ROOT_URL}/activationcodes/${userId}/${codeId}/deactivate`;
    };

    const showCodesData = async () => {
        try {
            const response = await fetch(
                getCodesUrl()
            );
            const data = await response.json();
            setCodesData(data);

        } catch (error) {
            console.error("Error fetching users data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        showCodesData();
    }, []);


    useEffect(() => {

    }, [codesData]);


    const formatDateForDisplay = (dateString) => {
        if (!dateString) {
            return "";
        }

        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
    };

    const ExportCSV = ({data}) => {
        const headers = [
            {label: 'Nombre', key: 'name'},
            {label: 'Licencias', key: 'licenseCount'},
            {label: 'Vencimiento', key: 'dueDate'}
        ];

        return (
            <CSVLink data={codesData} headers={headers} filename={"Códigos de Activación.csv"}>
                Descargar CSV
            </CSVLink>
        );
    };


    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
        },
        {
            name: "Licencias",
            selector: (row) => row.licenseCount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Fecha de Vencimiento",
            selector: (row) => formatDateForDisplay(row.dueDate),
            sortable: true,
            reorder: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="">
                    <button
                        className={`btn-status ${
                            row.active ? "btn-deactivate" : "btn-activate"
                        } me-2`}
                        onClick={() => handleActivateCode(row.active, row.id)}
                    >
                        {row.active ? (
                            <i className="toggle-off fa fa-power-off"></i>
                        ) : (
                            <i className="toggle-on fa fa-power-off"></i>
                        )}
                    </button>
                    <button
                        className="btn2 btn-modify me-2"
                        onClick={() => handleModify(row.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#modifyModal"
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                </div>
            ),
            ignoreRowClick: true,
        },

    ];

    ////////////////////////////
    // MANAGE ACTIVATION
    ////////////////////////////
    const handleActivateCode = async (codeStatus, codeId) => {

        const action = codeStatus ? "Desactivar" : "Activar";

        Swal.fire({
            html: `<h6>¿Confirmas que deseas <em style="text-decoration: underline;">${action}</em> este código de activación?</h6>`,
            padding: "3em 0em",
            showCancelButton: true,
            confirmButtonText: `Si, ${action}`,
            cancelButtonText: "No, Cancel",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleCodeStatus(codeStatus, userId, codeId);
            }
        });
    };

    const handleCodeStatus = async (codeStatus, userId, codeId) => {
        if (codeStatus === true) {
            try {
                setLoading(true);
                const response = await fetch(
                    codeDeactivateUrl(userId, codeId),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    setLoading(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "¡Código de activación desactivado con éxito!",
                        text: `El código de activación ha sido desactivado exitosamente.`,
                        showConfirmButton: false,
                        timer: 1000,
                    }).then(() => {

                        window.location.href = "/admin/activationCodes";
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo desactivar el código de activación en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "¡Error de procesamiento de activacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        } else {

            try {
                setLoading(true);
                const response = await fetch(
                    codeActivateUrl(userId, codeId),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    setLoading(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "¡Código de activación activado con éxito!",
                        text: `El código de activación ha sido activado exitosamente.`,
                        showConfirmButton: false,
                        timer: 2500,
                    }).then(() => {
                        window.location.href = "/admin/activationCodes";
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: "¡Ups! Algo salió mal",
                        text: `Lo sentimos, no se pudo activar el código de activación en este momento. Por favor, inténtalo de nuevo más tarde`,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.log("Error al activar el código de activación:", error);
                Swal.fire({
                    title: "¡Error de procesamiento de activacion!",
                    text:
                        "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                    icon: "error",
                });
            }
        }
    };

    ////////////////////////////
    // MODIFY
    ////////////////////////////
    const handleModify = (codeId) => {
        const code = codesData.find((code) => code.id === codeId);

        // Convertir la fecha al formato "YYYY-MM-DD"
        const formattedDueDateForInput = code.dueDate ? code.dueDate.substring(0, 4) + '-' + code.dueDate.substring(4, 6) + '-' + code.dueDate.substring(6, 8) : '';

        setSelectedCode(code);
        setSelectedCodeId(codeId);
        setSelectedLicense(code.licenseCount);
        setSelectedDueDate(formattedDueDateForInput); // Usar la fecha formateada para el input de fecha
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedCodeId(null);
    };

    const handleSaveChanges = async () => {
        try {
            const requestData = {
                licenseCount: selectedLicense,
                // Verificar si la fecha fue modificada
                dueDate: selectedDueDateObject !== null ? selectedDueDateObject : selectedCode.dueDate,
            };

            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}/activationcodes/${userId}/${selectedCodeId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                }
            );

            if (response.ok) {
                handleModalClose();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Código de Activación modificado con éxito!",
                    text: `El código de activación ha sido modificado exitosamente.`,
                    showConfirmButton: false,
                    timer: 1000,
                }).then(() => {
                    window.location.href = "/admin/activationCodes";
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al modificar el Código de Activación",
                    text: "No se pudo actualizar el Código de Activación. Por favor, inténtalo nuevamente más tarde.",
                });
                console.error("Failed to update activation code");
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error al modificar el Código de Activación",
                text: "No se pudo actualizar el Código de Activación. Por favor, inténtalo nuevamente más tarde.",
            });
            console.error("Error updating activation code:", error);
        }
    };

    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#0142FC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#f0f0f0",
            },
            action: {
                button: "#0142FC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const customStyles = {
        headCells: {
            style: {
                paddingTop: '20px',
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
        cells: {
            style: {
                paddingLeft: '35px',
                paddingRight: '35px',
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder />
            ) : (
                <div>
                    <ExportCSV data={codesData}/>
                    <DataTable
                        columns={columns}
                        data={codesData}
                        noDataComponent="Cargando..."
                        responsive={true}
                        theme={"tradeTheme"}
                        customStyles={customStyles}
                        style={{fontSize: "18px"}}

                    />
                </div>
            )}
            <Modal
                id="modifyModal"
                isOpen={modalOpen}
                onClose={handleModalClose}
                codeId={selectedCodeId}
                selectedCode={selectedCode}
                selectedLicense={selectedLicense}
                setSelectedLicense={setSelectedLicense}
                setSelectedCode={setSelectedCode}
                selectedDueDateObject={selectedDueDateObject}
                setSelectedDueDate={setSelectedDueDate}
                setSelectedDueDateObject={setSelectedDueDateObject}
                handleSaveChanges={handleSaveChanges}
            />
        </div>
    );
};

export default AdminAllActivationCodes;
