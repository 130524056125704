import React from 'react';
import PagosMain from './pagosMain';
import bannerbg from '../../assets/img/banner01.png';
import Footer from "../../components/MSFooter";
import Header from "../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const Pagos = () => {

    return (
        <React.Fragment>
            <Header parentMenu='my-courses' />
            <SiteBreadcrumb
                pageTitle="Pagos"
                pageName="Pagos"
                breadcrumbsImg={bannerbg}
            />
            <PagosMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default Pagos;