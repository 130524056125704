import React, {useEffect, useState} from "react";
import CategoriesSingle from "../Categories/CategoriesSingle";
import courseImg1 from "../../assets/img/courses/1.jpg"; // Imagen temporal para todas las categorías
import {Link} from 'react-router-dom';

const ShowCategories = () => {
    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // Fetch all categories data
                const categoriesResponse = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_CATEGORIES_GET_ALL}`
                );
                const categoriesData = await categoriesResponse.json();

                // Filter active courses
                const activeCoursesData = categoriesData.filter(category => category.active === true);

                // Set the fetched categories data
                setFetchData(activeCoursesData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const renderCategoryPanel = () => {
        return (
            <>
            <div className="row">
                <div className="row">
                    <div className="text-center pb-50 pt-30">
                        <h5>TOP CATEGORÍAS</h5>
                        <h3>Categorías Destacadas</h3>
                    </div>
                    {fetchData.map((category) => (
                        <div key={category.id} className="col-lg-3 col-md-6">
                            <CategoriesSingle
                                itemClass="categories-item md-mb-30"
                                title={category.name}
                                description={truncateText(category.description, 38)}
                                image={category.image === "" ? courseImg1 : category.image}
                                catLink={`/categories/${category.id}`}
                            />
                        </div>
                    ))}
                </div>
                <div className="sl-btn text-center pt-50">
                    <Link className="readon orange-btn main-home" to="/cursos">TODAS LAS CATEGORÍAS</Link>
                </div>
            </div>
            </>
        );
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <div
            id="rs-popular-course"
            className="rs-popular-courses categories style1 orange-style pt-50 pb-100 md-pt-70 md-pb-50"
        >
            <div className="container">
                {renderCategoryPanel()}
            </div>
        </div>
    );
};

export default ShowCategories;
