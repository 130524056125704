import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import useUserRoleLocalStorage from '../../UseUserRoleLocalStorage/UseUserRoleLocalStorage.js';
import useUserStore from '../../../store/useUserStore';
import useUserLocalStorage from '../../UseUserLocalStorage/UseUserLocalStorage';

const MobileNavBar = ({menuOpen, parentMenu}) => {
    const userRole = useUserRoleLocalStorage();
    const isAdmin = userRole === 'admin' || userRole === 'superadmin';
    const [adminMenu, setAdminMenu] = useState(false)
    const [reportsMenu, setReportsMenu] = useState(false)

    const history = useHistory();

    const userId = useUserLocalStorage();

    const openMobileMenu = menu => {
        if (menu === 'admin'){
            setAdminMenu(!adminMenu)
            setReportsMenu(false)
        } else if (menu === 'reports'){
            setAdminMenu(false)
            setReportsMenu(!reportsMenu)
        }
    };

    const clearUserProfileAndRedirectToLogin = () => {
        // Clear the userProfile from localStorage
        localStorage.removeItem("userProfile");
        useUserStore.setState({isAuthenticated: false});
        history.push("/login");
    };

    const handleLogout = () => {
        // Clear userProfile from localStorage and redirect to /login
        clearUserProfileAndRedirectToLogin();
    };

    return (
        <div className={"container relative"}>
            <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
                <div className="mobile-menu">
                    {userRole && (
                        <>
                            <ul className="nav-menu">
                                <li>
                                    <Link to="/mis-cursos" onClick={() => { openMobileMenu('my-courses'); }} className={parentMenu === 'my-courses' ? 'active-menu' : ''}>Mis Cursos</Link>
                                </li>

                                <li>
                                    <Link to="/cursos" onClick={() => { openMobileMenu('courses'); }} className={parentMenu === 'courses' ? 'active-menu' : ''}>Todos los Cursos</Link>
                                </li>

                                {isAdmin && (
                                    <>
                                        <li className={adminMenu ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                                            <Link to="#" onClick={() => { openMobileMenu('admin'); }} className={parentMenu === 'admin' ? 'active-menu' : ''}>Administración</Link>
                                            <ul className={adminMenu ? "sub-menu current-menu" : "sub-menu"}>
                                                <li><Link to="/admin/categories"> Categorías </Link></li>
                                                <li><Link to="/admin/cursos"> Cursos </Link></li>
                                                <li><Link to="/admin/request"> Solicitudes de Acceso </Link></li>
                                                <li><Link to="/admin/usuarios"> Usuarios </Link></li>
                                                <li><Link to="/admin/activationCodes"> Códigos de Activación </Link></li>
                                                <li><Link to="/groups"> Grupos </Link> </li>
                                                <li><Link to="/admin/campañas"> Campañas </Link></li>
                                                <li><Link to="/admin/email-loader"> Administrador de Grupos </Link></li>
                                            </ul>
                                        </li>
                                        <li className={reportsMenu ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                                            <Link to="#" onClick={() => { openMobileMenu('reports'); }} className={parentMenu === 'reports' ? 'active-menu' : ''}>Reportes</Link>
                                            <ul className={reportsMenu ? "sub-menu current-menu" : "sub-menu"}>
                                                <li>
                                                    <Link to="/report-users">Usuarios</Link>
                                                </li>
                                                <li>
                                                    <Link to="/report-courses">Cursos</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </>
                    )}
                    {userId && (
                        <p className="sidebarmenu-search px-4"> 
                            <Link to="#" onClick={handleLogout} className="rs-search" href="#">
                                <i className="fa fa-sign-out"></i> Logout
                            </Link>
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MobileNavBar;
