import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import AdminAllRequest from './table';

const Main = () => {
    return (
        <React.Fragment>
            <AdminAllRequest/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default Main;