import React from 'react';
import CampaignsCreator from './campaignCreator';
import CampaignMain from './campaignsMain'
import bannerbg from '../../assets/img/banner01.png';
import Footer from "../../components/MSFooter";
import Header from "../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

const Campañas = () => {
  return (
    <React.Fragment>
      <Header parentMenu='my-courses' />
      <SiteBreadcrumb
        pageTitle="Campañas"
        pageName="Campañas"
        breadcrumbsImg={bannerbg}
      />
      <CampaignMain/>

      <Footer />
    </React.Fragment>
  );
};

export default Campañas;