import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import Footer from '../../../components/MSFooter';

// Image
import bannerbg from '../../../assets/img/banner02.png';
import Main from './main';
import CreateModal from './createModal';

const AdminAllCategories = () => {
    return (
        <React.Fragment>
            <Header parentMenu='admin-categories'/>
            <SiteBreadcrumb pageTitle="Categorías" pageName="Categorías" titleClass="page-title white-color" breadcrumbsImg={bannerbg} />
            <CreateModal/>
            <Main/>
            <Footer/>
        </React.Fragment>
    );
}

export default AdminAllCategories;