import React from 'react';
import Details from './details';
import ScrollToTop from '../../../components/Common/ScrollTop';

const Main = () => {

    return (
        <React.Fragment>
            <Details/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default Main;