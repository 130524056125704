import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import Main from './main';
import bannerBg from '../../../assets/img/banner02.png';
import Footer from "../../../components/MSFooter";

const Groups = () => {

    return (
        <React.Fragment>
            <Header parentMenu={'groups'}/>
            <SiteBreadcrumb pageTitle="Enviar mensajes" pageName="Groups" titleClass="page-title white-color" breadcrumbsImg={bannerBg} />
            <Main/>
            <Footer/>
        </React.Fragment>
    );
}

export default Groups;
