import React from 'react';
import AddCourseForm from '../../../components/Create/AddCourseForm';

const CreateMain = () => {
    return (
        <React.Fragment>
            <div className="rs-contact style1 create">
                <div className="container contact-widget">
                    <AddCourseForm/>
                </div>
            </div>
        </React.Fragment>
    );
}


export default CreateMain;