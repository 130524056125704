import React, {useEffect, useState} from "react";
import {v4, v4 as uuidv4} from "uuid";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {imageStorage} from "../../config/firebaseStorageConfig";

const initialFormData = {
    name: "",
    description: "",
    image: "",
    detail: "",
    duration: "",
    modules: [],
};

const initialContent = {
    id: "",
    type: "",
    title: "",
    url: "",
    text: "",
    questions: [],
    files: [],
};

const initialQuestion = {
    id: "",
    question: "",
    answers: ["", "", "", ""],
    correctAnswer: "",
};

const AddCourseForm = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [categories, setCategories] = useState(null);
    const [detailValue, setDetailValue] = useState("");
    const [moduleContentValue, setModuleContentValue] = useState({});
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    //Images Uploads
    const handleUpload = async (e) => {
        setLoading(true);

        //upload image
        if (e.target.files) {
            const file = e.target.files[0];
            const images = ref(imageStorage, `courses/cat_${v4()}`);
            const fileUpload = await uploadBytes(images, file);
            const filePath = await getDownloadURL(fileUpload.ref);
            setImage(() => filePath);

            // Set image preview
            setImagePreview(URL.createObjectURL(file));
        }
        setLoading(false);
    };

    //File Uploads
    const handleFileUpload = async (e, moduleIndex, contentIndex) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const fileRef = ref(imageStorage, `courses/files_${uuidv4()}`);
            const fileUpload = await uploadBytes(fileRef, file);
            const filePath = await getDownloadURL(fileUpload.ref);
    
            setFormData((prevFormData) => {
                const updatedModules = [...prevFormData.modules];
                const updatedContent = {...updatedModules[moduleIndex].content[contentIndex]};
                updatedContent.files.push({ filename: file.name, url: filePath });
                updatedModules[moduleIndex].content[contentIndex] = updatedContent;
    
                return {...prevFormData, modules: updatedModules};
            });
        }
    };


    ////////////////////////////
    // LOAD CATEGORIES
    ////////////////////////////
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_CATEGORIES_GET_ALL}`
                );
                const data = await response.json();
                const filtered = data.filter((category) => category.active === true);
                setCategories(filtered);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        // Call the async function
        fetchData();
        // eslint-disable-next-line
    }, []);

    ////////////////////////////
    // SELECT CATEGORY
    const handleCategoryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(
            (option) => option.value
        );

        setFormData((prevState) => ({
            ...prevState,
            category: selectedOptions,
        }));
    };

    ////////////////////////////
    // CHANGE SELECT & RANDOM
    ////////////////////////////
    const handleChangeSelect = (e, moduleIndex) => {
        const {value: newOption} = e.target;

        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const currentContent = updatedModules[moduleIndex].content.slice(-1)[0];

            if (!currentContent) {
                return prevFormData;
            }

            switch (newOption) {
                case "video":
                    currentContent.type = "video";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.text = "";
                    currentContent.questions = [];
                    currentContent.files = [];
                    break;
                case "text":
                    currentContent.type = "text";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.questions = [];
                    currentContent.files = [];
                    break;
                case "quiz":
                    currentContent.type = "quiz";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.text = "";
                    currentContent.questions = [initialQuestion];
                    currentContent.files = [];
                    break;
                default:
                    break;
            }

            return {
                ...prevFormData,
                modules: updatedModules,
            };
        });
    };

    const generateUniqueId = () => {
        return uuidv4();
    };

    ////////////////////////////
    // COURSE NAME, DESCRIPTION
    ////////////////////////////
    const handleInputChange = (e) => {
        const {name, value} = e.target;

        if (name === 'name' && value.length > 20) {
            alert('El título no puede tener más de 20 caracteres.');
            return;
        }

        if (name === 'description' && value.length > 80) {
            alert('La descripción no puede tener más de 80 caracteres.');
            return;
        }

        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    //////////////////////////////////////////////
    // MODULE FUNCTIONS
    //////////////////////////////////////////////
    ////////////////////////////
    // ADD MODULE
    const handleAddModule = () => {
        const newModule = {
            id: `module${formData.modules.length + 1}`,
            name: "",
            content: [],
        };
        setFormData((prevState) => ({
            ...prevState,
            modules: [...prevState.modules, newModule],
        }));
    };
    ////////////////////////////
    // SET MODULE NAME
    const handleModuleChange = (moduleIndex, e) => {
        const {name, value} = e.target;
        setFormData((prevState) => {
            const updatedModules = [...prevState.modules];
            updatedModules[moduleIndex] = {
                ...updatedModules[moduleIndex],
                [name]: value,
            };
            return {...prevState, modules: updatedModules};
        });
    };

    ////////////////////////////
    // DELETE MODULE
    const handleDeleteModule = (moduleIndex) => {
        Swal.fire({
            title: "Confirme Acción",
            text: `¿Está seguro que desea eliminar este módulo?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData((prevState) => {
                    const updatedModules = [...prevState.modules];
                    updatedModules.splice(moduleIndex, 1);
                    return { ...prevState, modules: updatedModules };
                });
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'El módulo ha sido eliminado.',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        });
    };

    //////////////////////////////////////////////
    // CONTENT FUNCTIONS
    //////////////////////////////////////////////
    ////////////////////////////
    // ADD CONTENT
    const handleAddContent = (moduleIndex) => {
        setFormData((prevFormData) => {
            let newContent = {
                ...initialContent,
                id: generateUniqueId(),
                type: "video",
                title: "",
                url: "",
                text: "",
                questions: [],
                files: [],
            };

            const updatedModules = [...prevFormData.modules];
            updatedModules[moduleIndex].content.push(newContent);

            return {
                ...prevFormData,
                modules: updatedModules,
            };
        });
    };

    // Handle rich text editor change
    const handleDetailChange = (value) => {
        setDetailValue(value);
        setFormData((prevData) => ({...prevData, detail: value}));
    };

    ////////////////////////////
    // HANDLE CONTENT CHANGE
    const handleModuleContentChange = (value, moduleIndex, contentIndex) => {
        setModuleContentValue((prevState) => ({
            ...prevState,
            [`${moduleIndex}-${contentIndex}`]: value,
        }));
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedContent = {...updatedModules[moduleIndex].content[contentIndex], text: value};
            updatedModules[moduleIndex].content[contentIndex] = updatedContent;
            return {...prevFormData, modules: updatedModules};
        });
    };

    const handleContentChange = (e, moduleIndex, contentIndex) => {
        const {name, value} = e.target;

        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};

            if (updatedContent.type === "video" || updatedContent.type === "text") {
                updatedContent[name] = value;
            } else if (updatedContent.type === "quiz") {
                const updatedQuestion = {
                    ...updatedContent.questions[0],
                    [name]: value,
                };
                updatedContent.questions = [updatedQuestion];
            }

            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // HANDLE DELETE CONTENT
    const handleDeleteContent = (moduleIndex, contentIndex) => {
        Swal.fire({
            title: "Confirme Acción",
            text: `¿Está seguro que desea eliminar este contenido?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData((prevFormData) => {
                    const updatedModules = [...prevFormData.modules];
                    const updatedModule = { ...updatedModules[moduleIndex] };
                    const updatedContent = [...updatedModule.content];
                    updatedContent.splice(contentIndex, 1);
                    updatedModule.content = updatedContent;
                    updatedModules[moduleIndex] = updatedModule;

                    return { ...prevFormData, modules: updatedModules };
                });
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'El contenido ha sido eliminado.',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        });
    };

    const handleDeleteFile = (moduleIndex, contentIndex, fileIndex) => {
        setFormData((prevFormData) => {
          const updatedModules = [...prevFormData.modules];
          const updatedContent = [...updatedModules[moduleIndex].content];
          const updatedFiles = [...updatedContent[contentIndex].files];
          updatedFiles.splice(fileIndex, 1);
          updatedContent[contentIndex].files = updatedFiles;
          updatedModules[moduleIndex].content = updatedContent;
          return { ...prevFormData, modules: updatedModules };
        });
    };
    ////////////////////////////
    // QUIZ FUNCTIONS
    ////////////////////////////
    ////////////////////////////
    // QUESTION CHANGE
    const handleQuestion = (e, questionIndex, moduleIndex, contentIndex) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {
                ...updatedContent.questions[questionIndex],
                question: value,
            };
            updatedContent.questions[questionIndex] = updatedQuestion;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;
            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // ANSWER CHANGE
    const handleAnswerChange = (
        questionIndex,
        answerIndex,
        e,
        moduleIndex,
        contentIndex
    ) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {...updatedContent.questions[questionIndex]};
            const updatedAnswers = [...updatedQuestion.answers];
            updatedAnswers[answerIndex] = value;
            const updatedQuestionWithAnswers = {
                ...updatedQuestion,
                answers: updatedAnswers,
            };
            const updatedQuestions = [...updatedContent.questions];
            updatedQuestions[questionIndex] = updatedQuestionWithAnswers;

            updatedContent.questions = updatedQuestions;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // SET CORRECT ANSWER
    const handleCorrectAnswerChange = (
        e,
        questionIndex,
        moduleIndex,
        contentIndex
    ) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {...updatedContent.questions[questionIndex]};
            updatedQuestion.correctAnswer = updatedQuestion.answers[value];
            updatedContent.questions[questionIndex] = updatedQuestion;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;
            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // DELETE QUESTION
    const handleDeleteQuestion = (questionIndex, moduleIndex, contentIndex) => {
        Swal.fire({
            title: "Confirme Acción",
            text: `¿Está seguro que desea eliminar esta pregunta?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData((prevFormData) => {
                    const updatedModules = [...prevFormData.modules];
                    const updatedModule = { ...updatedModules[moduleIndex] };
                    const updatedContent = { ...updatedModule.content[contentIndex] };
                    updatedContent.questions.splice(questionIndex, 1);
                    updatedModule.content[contentIndex] = updatedContent;
                    updatedModules[moduleIndex] = updatedModule;

                    return { ...prevFormData, modules: updatedModules };
                });
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'La pregunta ha sido eliminada.',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        });
    };

    ////////////////////////////
    // ADD QUESTION
    const handleAddQuestion = (moduleIndex, contentIndex) => {
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const newQuestion = {...initialQuestion, id: generateUniqueId()};

            updatedContent.questions.push(newQuestion);
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // SUBMIT FORM
    ////////////////////////////
    const handleSubmit = async (e) => {
        e.preventDefault();

        const courseData = {
            ...formData,
            detail: detailValue,
            name: formData.name,
            description: formData.description,
            image: image,
            duration: formData.duration,
            categories: formData.category,
            completed: false,
            notes: '',
            modules: formData.modules.map((module) => {
                const formattedModule = {
                    name: module.name,
                    completed: false,
                    content: module.content.map((content) => {
                        let formattedContent = {
                            type: content.type,
                            title: content.title,
                            completed: false,
                            files: content.files,
                        };
                        switch (content.type) {
                            case "video":
                                formattedContent.url = content.url;
                                break;
                            case "text":
                                formattedContent.text = content.text;
                                break;
                            case "quiz":
                                delete formattedContent.title;
                                formattedContent.questions = content.questions.map((question) => {
                                    const formattedQuestion = {
                                        question: question.question,
                                        answers: question.answers,
                                        correctAnswer: question.correctAnswer,
                                    };
                                    delete formattedQuestion.id;
                                    return formattedQuestion;
                                });
                                break;
                            default:
                                break;
                        }
                        return formattedContent;
                    }),
                };
                delete module.id;
                return formattedModule;
            }),
        };

        console.log(courseData) //TODO: DARIO

        try {
            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_POST}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(courseData),
                }
            );

            if (response.ok) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Curso creado con éxito!",
                    text: `El curso ha sido creado exitosamente. ¡Comienza a explorar y gestionar tu nuevo curso ahora mismo!`,
                    showConfirmButton: false,
                    timer: 2500,
                }).then(() => {
                    window.location.href = "/admin/cursos";
                });
                setFormData(initialFormData);
            } else {
                Swal.fire({
                    title: "¡Ups! Algo salió mal",
                    text: `Lo sentimos, no se pudo crear el curso en este momento. Por favor, inténtalo de nuevo más tarde`,
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "¡Error de procesamiento!",
                text:
                    "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                icon: "error",
            });
        }
    };

    const modulo = {
        toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {list: "ordered"},
                {list: "bullet"},
                {indent: "-1"},
                {indent: "+1"},
            ],
            ["link", "image", "video"],
        ]
    }

    ////////////////////////////
    // SHOW DATA
    ////////////////////////////

    return (
        <form onSubmit={handleSubmit} className="container module-box box pb-40">
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <div className={`content ${loading ? 'loading' : ''}`}>
                <h2>Crear Curso</h2>

                <div className="mb-3">
                    <label className="form-label">Nombre:</label>
                    <input
                        required={true}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label mt-2">Descripción:</label>
                    <textarea
                        required={true}
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label mt-2">Detalle del curso:</label>
                    <ReactQuill
                        theme="snow"
                        value={detailValue}
                        onChange={handleDetailChange}
                        modules={modulo}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label text-large">Duración (horas):</label>
                    <input
                        required={true}
                        type="number"
                        name="duration"
                        value={formData.duration}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                        
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label text-large">Imagen portada (tamaño de imagen sugerida 550px x 260px):</label>
                    <input
                        type="file"
                        name="imagen"
                        onChange={async (e) => await handleUpload(e)}
                        className="form-control mb-20"
                    />
                    {imagePreview && (
                        <div className="image-container mt-3" style={{ position: "relative", display: "inline-block" }}>
                        <button
                            type="button"
                            onClick={() => {
                                setImage('');
                                setImagePreview('');
                            }}
                            className="delete-button"
                        >
                            &times;
                        </button>
                        
                        <img src={imagePreview} alt="Vista previa de la imagen" className="img-preview" style={{ maxWidth: "100%", borderRadius: "10px" }} />
                    </div>
                    )}
                </div>

                <div className="mb-3">
                    {categories && (
                        <>
                            <label className="form-label">Seleccione la categoría correspondiente (una o más):</label>
                            <select
                                className="form-control"
                                multiple
                                value={formData.category}
                                onChange={(e) => handleCategoryChange(e)}
                            >
                                <option disabled value="">
                                    Seleccionar una opción
                                </option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                </div>

                {/* Create Module Section Start */}
                
                {/* {formData.modules.length !== 0 && <h4 className="mt-50">Crear módulos</h4>} */}
            
                {formData.modules.map((module, moduleIndex) => (
                    <div className="module">
                        <div key={moduleIndex} className="mb-20 boxs py-4 rounded">
                            <div className="container">
                                <div className="border-bottom">
                                    <div className="row">
                                        <div className="col-md-10 d-flex align-items-center">
                                            <div className="d-flex">
                                                <h6>Nombre del Módulo:</h6>
                                            </div>
                                        </div>

                                        <div className="col-md-2 d-flex align-items-center justify-content-center">
                                            <div className="d-flex justify-content-center sl-btn">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        handleDeleteModule(moduleIndex);
                                                    }}
                                                    className="btn btn-del-modul"
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-lila">

                                        <input
                                            required={true}
                                            type="text"
                                            name="name"
                                            value={module.name}
                                            onChange={(e) => handleModuleChange(moduleIndex, e)}
                                            className="form-control form-lila mb-20"
                                            placeholder="Nombre del Módulo"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                {module.content.length !== 0 && (
                                    <h5 className="mt-20">Crear contenido:</h5>
                                )}
                            </div>
                            {module.content.map((content, contentIndex) => (
                                <div key={contentIndex} className="mb-20 rounded module-shadow border-bottom">
                                    <div className="container">
                                        
                                            {/* Selector Option Start */}
                                            <div className="row">
                                                <div className="form-group col-md-10">
                                                    <label className="form-label">Seleccione una opcion de creacion de contenido:</label>
                                                    <select
                                                        className="form-control form-lila"
                                                        value={content.type}
                                                        onChange={(e) => {
                                                            handleChangeSelect(e, moduleIndex);
                                                        }}
                                                    >
                                                        <option value="video">Cargar Video</option>
                                                        <option value="text">Cargar Artículo</option>
                                                        <option value="quiz">Cargar Test de preguntas y respuestas</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleDeleteContent(moduleIndex, contentIndex);
                                                            }}
                                                            className="btn btn-del-cont "
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Selector Option End */}

                                                {/* Video Option Start */}
                                                <div className="container">
                                                {content.type === "video" && (
                                                    <>
                                                        <h5 className="mt-20">Titulo del video:</h5>
                                                        
                                                        <input
                                                            required={true}
                                                            type="text"
                                                            name="title"
                                                            value={content.title || ""}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="Titulo del video"
                                                        />

                                                        <label className="form-label mt-2 mb-1">Url:</label>
                                                        <input
                                                            required={true}
                                                            type="text"
                                                            name="url"
                                                            value={content.url || ""}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="url del video en Youtube"
                                                        />
                                                    </>
                                                )}
                                                </div>
                                                {/* Video Option End */}

                                                {/* Text Option Start */}
                                                {content.type === "text" && (
                                                    <div>
                                                        <h5 className="mt-20">Titulo del Artículo</h5>
                                                        
                                                        <input
                                                            required={true}
                                                            type="text"
                                                            name="title"
                                                            value={content.title}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="Titulo del articulo"
                                                        />

                                                        <label className="form-label mt-2 mb-1">Articulo:</label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={
                                                                moduleContentValue[
                                                                    `${moduleIndex}-${contentIndex}`
                                                                    ] || content.text
                                                            }
                                                            onChange={(value) =>
                                                                handleModuleContentChange(
                                                                    value,
                                                                    moduleIndex,
                                                                    contentIndex
                                                                )
                                                            }
                                                            modules={modulo}
                                                            className="quill-lila"
                                                        />
                                                    </div>
                                                )}
                                                {/* Text Option End */}

                                                {content.type === "video" || content.type === "text" ? (
                                                    <div>
                                                        <label className="form-label mt-2 mb-1">Subir archivos:</label>
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleFileUpload(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                        />
                                                        <ul>
                                                            {content.files.map((file, fileIndex) => (
                                                                <li key={fileIndex} className="flex items-center justify-between">
                                                                <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                                                    {file.filename}
                                                                </a>
                                                                <div className="image-container mt-3" style={{ position: "relative", display: "inline-block" }}>
                                                                    <button
                                                                    type="button"
                                                                    onClick={() => handleDeleteFile(moduleIndex, contentIndex, fileIndex)}
                                                                    className="delete-button-file"
                                                                    >
                                                                    &times;
                                                                    </button>
                                                                </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : null}

                                                {/* Quiz Option Start */}
                                                {content.type === "quiz" && (
                                                    <div>
                                                        <div className="container mt-4 quiz style1">
                                                            <h5 className="mt-20">Quiz:</h5>

                                                            {content.questions.map((question, questionIndex) => (
                                                                <div key={questionIndex}
                                                                    className="mb-20 rounded module-shadow">
                                                                    <div className="container">
                                                                        <div className="row contact-widget module-box">
                                                                            <div className="col-md-10  rounded pt-3">
                                                                                <div className="form-group">
                                                                                    <label>Ingrese la pregunta o
                                                                                        trivia:</label>
                                                                                    <textarea
                                                                                        required={true}
                                                                                        name="question"
                                                                                        className="form-control form-lila"
                                                                                        value={question.question}
                                                                                        onChange={(e) =>
                                                                                            handleQuestion(e, questionIndex, moduleIndex, contentIndex)
                                                                                        }
                                                                                        placeholder={`Pregunta...`}
                                                                                    ></textarea>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <label>Escriba las posibles
                                                                                        respuestas:</label>
                                                                                    {question.answers.map((answer, answerIndex) => (
                                                                                        <div key={answerIndex}
                                                                                            className="mb-10">
                                                                                            <input
                                                                                                required={true}
                                                                                                name="answer"
                                                                                                type="text"
                                                                                                className="form-control form-lila"
                                                                                                value={answer}
                                                                                                onChange={(e) =>
                                                                                                    handleAnswerChange(
                                                                                                        questionIndex,
                                                                                                        answerIndex,
                                                                                                        e,
                                                                                                        moduleIndex,
                                                                                                        contentIndex
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ))}

                                                                                    <div className="form-group">
                                                                                        <label>Seleccione la respuesta
                                                                                            correcta:</label>
                                                                                        <select
                                                                                            required={true}
                                                                                            className="form-control form-lila"
                                                                                            value={
                                                                                                question.correctAnswer
                                                                                                    ? question.answers.indexOf(question.correctAnswer)
                                                                                                    : ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleCorrectAnswerChange(
                                                                                                    e,
                                                                                                    questionIndex,
                                                                                                    moduleIndex,
                                                                                                    contentIndex
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {!question.correctAnswer && (
                                                                                                <option value="">Select an
                                                                                                    option</option>
                                                                                            )}
                                                                                            {question.answers.map((answer, index) => (
                                                                                                <option key={index}
                                                                                                        value={index}>
                                                                                                    {answer}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="col-md-2 d-flex align-items-center justify-content-center">
                                                                                <div
                                                                                    className="d-flex justify-content-center">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            handleDeleteQuestion(
                                                                                                questionIndex,
                                                                                                moduleIndex,
                                                                                                contentIndex
                                                                                            );
                                                                                        }}
                                                                                        className="btn btn-del-cont "
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            <div
                                                                className="container d-flex justify-content-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleAddQuestion(moduleIndex, contentIndex);
                                                                    }}
                                                                    className="btn btn-add"
                                                                >
                                                                    Agregar Pregunta
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            {/* Quiz Option End */}
                                            </div>

                                            {/* <div className="col-md-2 d-flex align-items-center justify-content-center">
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            handleDeleteContent(moduleIndex, contentIndex);
                                                        }}
                                                        className="btn btn-del-cont "
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div> */}
                                        
                                    </div>
                                </div>
                            ))}

                            <div className="container d-flex justify-content-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleAddContent(moduleIndex);
                                    }}
                                    className="btn btn-secondary btn-add btn-add-cont me-2 m-10"
                                >
                                    + Nuevo Contenido
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="container d-flex justify-content-center text-center">
                        <div className="col-lg-6 col-md-6">
                            <button
                                type="button"
                                onClick={handleAddModule}
                                className="btn btn-add"
                            >
                                Agregar Módulo
                            </button>
                        </div>
                        {/* Create Module Section End */}
                        <div className="col-lg-6 col-md-6">
                            <button type="submit" className="btn btn-course-create">
                                Crear Curso
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddCourseForm;