import { useEffect, useState } from "react";
import axios from 'axios'

const useUserRoleLocalStorage = () => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const getUserRoleFromLocalStorage = async () => {
      // Obtener el valor almacenado en la clave "userProfile" del Local Storage
      const userProfileString = localStorage.getItem("userProfile");

      // Verificar si userProfileString es null o undefined
      if (!userProfileString) return null;

      const userProfile = JSON.parse(userProfileString);

      // Obtener el token del objeto userProfile
      const token = userProfile?.token;

      // Verificar si el token es null o undefined
      if (!token) return null;

      // Obtener el email del usuario del token
      const email = token.email;

      const requestBody = {
        email
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/auth/getUserInfo`,
          requestBody
        );
        setUserRole(response.data.token.role);

      } catch (error) {
        console.log(error)
      }
    };

    getUserRoleFromLocalStorage();
  }, []);
  return userRole;
};

export default useUserRoleLocalStorage;
