const UpdateModal = ({
                         emailId,
                         isOpen,
                         onClose,
                         setSelectedSubject,
                         setSelectedMessage,
                         selectedSubject,
                         selectedMessage,
                         handleSaveChanges
                     }) => {

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} id={emailId} tabIndex="-1" aria-labelledby={`${emailId}Label`}
             aria-hidden="true" style={{display: isOpen ? 'block' : 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={`${emailId}Label`}>Editar Mensaje</h1>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className="mb-3">
                            <label className="form-label">Asunto:</label>
                            <input type="text" className="form-control" id="subject" value={selectedSubject}
                                   onChange={(e) => setSelectedSubject(e.target.value)}/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Mensaje:</label>
                            <input type="text" className="form-control" id="message" value={selectedMessage}
                                   onChange={(e) => setSelectedMessage(e.target.value)}/>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn2 btn-delete me-2" onClick={onClose}>Cerrar</button>
                            <button type="button" className="btn2 btn-delete me-2" onClick={handleSaveChanges}>Guardar
                                Cambios
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateModal;




