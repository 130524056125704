import React, {useEffect, useState} from 'react';
import {Button, Col, Collapse, Container, ListGroup, ListGroupItem, ProgressBar, Row, Spinner} from 'react-bootstrap';
import VideoContent from "../../../components/CourseFlow/VideoContent";
import TextContent from "../../../components/CourseFlow/TextContent";
import QuizContent from "../../../components/CourseFlow/QuizContent";
import {Redirect, useParams} from 'react-router-dom';
import useUserLocalStorage from "../../../components/UseUserLocalStorage/UseUserLocalStorage";

const CourseFlow = ({ courseData, notes }) => {
    const [openModuleIndexes, setOpenModuleIndexes] = useState([]);
    const [selectedContent, setSelectedContent] = useState(null);
    const [menuOpen, setMenuOpen] = useState(true);
    const [redirectToAccount, setRedirectToAccount] = useState(false);
    const [isQuizContent, setIsQuizContent] = useState(false);
    const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
    const [progress, setProgress] = useState(courseData.progress || 0);
    const [localCourseData, setLocalCourseData] = useState(courseData);
    const [loading, setLoading] = useState(false);

    //MODIFICAR
    const userId = useUserLocalStorage();
    const { id } = useParams();

    const handleContentClick = (moduleIndex, contentIndex, contentType) => {
        setSelectedContent({ moduleIndex, contentIndex });
        setIsQuizContent(contentType === 'quiz');
        setIsQuizSubmitted(false);
    };

    const handleModuleClick = (index) => {
        setOpenModuleIndexes(openModuleIndexes.includes(index) ? openModuleIndexes.filter((i) => i !== index) : [...openModuleIndexes, index]);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleQuizCompletion = () => {
        setIsQuizSubmitted(true);
    };

    const markContentComplete = async (requestData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/user/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Failed to send POST request');
            }

            const responseData = await response.json();
            return responseData.progress;
        } catch (error) {
            console.error('Error sending POST request:', error);
        }
    };

    const handleNextContent = async () => {
        setLoading(true);

        const { moduleIndex, contentIndex } = selectedContent;
        const module = localCourseData.modules[moduleIndex];
        const nextContentIndex = contentIndex + 1;

        const isLastContent = moduleIndex === localCourseData.modules.length - 1 && nextContentIndex === module.content.length;

        module.content[contentIndex].completed = true;

        const requestBody = {
            courseId: id,
            moduleId: module.id,
            contentId: module.content[contentIndex].id,
            progress: progress,
            notes: notes
        };

        const updatedProgress = await markContentComplete(requestBody);
        setProgress(updatedProgress);

        setLocalCourseData({
            ...localCourseData,
            modules: localCourseData.modules.map((mod, idx) =>
                idx === moduleIndex ? { ...mod, content: mod.content.map((cont, i) => i === contentIndex ? { ...cont, completed: true } : cont) } : mod
            )
        });

        if (!isLastContent) {
            if (nextContentIndex < module.content.length) {
                setSelectedContent({ moduleIndex, contentIndex: nextContentIndex });
                setIsQuizContent(module.content[nextContentIndex]?.type === 'quiz');
                setIsQuizSubmitted(false);
            } else {
                const nextModuleIndex = moduleIndex + 1;
                if (nextModuleIndex < localCourseData.modules.length) {
                    setOpenModuleIndexes([...openModuleIndexes, nextModuleIndex]);
                    setSelectedContent({ moduleIndex: nextModuleIndex, contentIndex: 0 });
                    setIsQuizContent(localCourseData.modules[nextModuleIndex].content[0]?.type === 'quiz');
                    setIsQuizSubmitted(false);
                }
            }
        } else {
            setRedirectToAccount(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (localCourseData.modules.length > 0 && selectedContent === null) {
            setOpenModuleIndexes([0]);
            setSelectedContent({ moduleIndex: 0, contentIndex: 0 });
            setIsQuizContent(localCourseData.modules[0].content[0]?.type === 'quiz');
        }
    }, [localCourseData.modules, selectedContent]);

    if (redirectToAccount) {
        return <Redirect to="/mis-cursos" />;
    }

    return (
        <Container fluid className="course-container position-relative px-0 py-3">
            <Row className='m-0'>
                <Col xs={12} md={menuOpen ? 9 : 12} className="d-flex flex-column">
                    <div className="content-box">
                        <div className="pe-5 pb-30"><ProgressBar now={progress} animated label={`${progress}% COMPLETADO`} className="mt-3 line" /></div>
                        {selectedContent !== null && (
                            <>
                                {localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]?.type === 'video' && (
                                    <VideoContent
                                        content={localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]} />
                                )}
                                {localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]?.type === 'text' && (
                                    <TextContent
                                        content={localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]} />
                                )}
                                {localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]?.type === 'quiz' && (
                                    <QuizContent
                                        content={localCourseData.modules[selectedContent.moduleIndex].content[selectedContent.contentIndex]}
                                        handleCompletion={handleQuizCompletion} />
                                )}
                                <div className="d-grid d-md-flex justify-content-md-end">
                                    <Button onClick={handleNextContent} className="btn-course" variant="success" disabled={(isQuizContent && !isQuizSubmitted) || loading}>
                                        {loading ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="me-2" />
                                                {selectedContent.moduleIndex === localCourseData.modules.length - 1 && selectedContent.contentIndex === localCourseData.modules[selectedContent.moduleIndex].content.length - 1 ? 'Finalizar' : 'Siguiente'}
                                            </>
                                        ) : (
                                            selectedContent.moduleIndex === localCourseData.modules.length - 1 && selectedContent.contentIndex === localCourseData.modules[selectedContent.moduleIndex].content.length - 1 ? 'Finalizar' : 'Siguiente'
                                        )}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Col>

                {menuOpen && (
                    <Col xs={12} md={3} className="d-flex flex-column content-menu">
                        <div className="img-detail-course pb-20 pt-0">
                            <img className="crop" src={localCourseData.image}/>
                        </div>
                        {localCourseData.modules.map((module, moduleIndex) => (
                            <div key={moduleIndex} className="mb-1">
                                <ListGroupItem
                                    onClick={() => handleModuleClick(moduleIndex)}
                                    style={{ cursor: 'pointer', fontWeight: openModuleIndexes.includes(moduleIndex) ? 'bold' : 'normal' }}
                                    className={`button-list ${openModuleIndexes.includes(moduleIndex) ? 'active' : ''}`}
                                >
                                    {module.name} <i className={` fa ${openModuleIndexes.includes(moduleIndex) ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </ListGroupItem>
                                <Collapse in={openModuleIndexes.includes(moduleIndex)}>
                                    <ListGroup>                                     
                                        {module.content.map((content, contentIndex) => (
                                            <ListGroupItem
                                                key={`${moduleIndex}-${contentIndex}`}
                                                action
                                                onClick={() => handleContentClick(moduleIndex, contentIndex, content.type)}
                                                style={{ cursor: 'pointer', backgroundColor: selectedContent && selectedContent.moduleIndex === moduleIndex && selectedContent.contentIndex === contentIndex ? '#f0f0f0' : 'transparent' }}
                                                className={`button-menu ${selectedContent && selectedContent.moduleIndex === moduleIndex && selectedContent.contentIndex === contentIndex ? 'active' : ''}`}
                                            >
                                                {content.completed && (<i className="completed-icon fa fa-check"></i>)}
                                                {content.type === 'quiz' ? 'Quiz' : content.title}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </Collapse>
                            </div>
                        ))}
                    </Col>
                )}

                <Button onClick={toggleMenu}
                    className={`menu-toggle-button position-absolute top-1 ${menuOpen ? 'pill-position' : 'end-0'} pill-button`}>
                    {menuOpen ? (<i className="fa fa-chevron-right"></i>) : (<i className="fa fa-chevron-left"></i>)}
                </Button>
            </Row>
        </Container>
    );
};

export default CourseFlow;


