import React, {Component} from 'react';
import SliderDefault from '../../components/Slider/SliderDefault';
import ScrollToTop from '../../components/Common/ScrollTop';
import LastCourses from '../../components/LastCourses/LastCourses';
import ShowCategories from '../../components/ShowCategories/ShowCategories';


class HomeMain extends Component {

    render() {

        return (
            <React.Fragment>
                <SliderDefault/>
                <LastCourses/>
                <ShowCategories/>
                <ScrollToTop
                    scrollClassName="scrollup orange-color"
                />

            </React.Fragment>
        );
    }
}

export default HomeMain;