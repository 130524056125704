import create from "zustand";

const useUserStore = create((set) => ({
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    setIsAuthenticated: (isAuthenticated) => {
        localStorage.setItem('isAuthenticated', isAuthenticated);
        set({ isAuthenticated });
    },
    userData: JSON.parse(localStorage.getItem('userData')),
    setUserData: (userData) => {
        localStorage.setItem('userData', JSON.stringify(userData));
        set({ userData });
    },
}));

export default useUserStore;