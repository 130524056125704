import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import 'bootstrap/dist/css/bootstrap.min.css';

const PagosMain = () => {
  
  // Devuelve todo el html
  return (
    <React.Fragment>
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <p>pagosMain</p>
    </React.Fragment>
  );
};

export default PagosMain;