import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import Footer from '../../../components/MSFooter';
import ButtonLink from '../../../components/Button/button';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

// Image
import bannerbg from '../../../assets/img/banner02.png';
import Main from './main';

const AdminAllUsers = () => {
    return (
        <React.Fragment>
            <Header parentMenu='create' />
            <SiteBreadcrumb pageTitle="Usuarios" pageName="Usuarios" titleClass="page-title white-color" breadcrumbsImg={bannerbg} />
            <ButtonLink to="/register">Nuevo Usuario</ButtonLink>
            <Main/>
            <Footer/>
        </React.Fragment>
    );
}

export default AdminAllUsers;