import React, {useEffect, useState} from "react";
import {v4, v4 as uuidv4} from "uuid";
import Swal from "sweetalert2";
import {useParams} from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"
import {getDownloadURL, ref, uploadBytes, deleteObject} from "firebase/storage";
import {imageStorage} from "../../config/firebaseStorageConfig";

const initialFormData = {
    name: "",
    description: "",
    detail: "",
    duration: "",
    image: "",
    modules: [],
    category: [],
};

const initialContent = {
    id: "",
    type: "",
    title: "",
    url: "",
    text: "",
    questions: [],
    files: [],
};

const initialQuestion = {
    id: "",
    question: "",
    answers: ["", "", "", ""],
    correctAnswer: "",
};

const ModifyCourseForm = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [categories, setCategories] = useState([]);
    const [detailValue, setDetailValue] = useState("");
    const [moduleContentValue, setModuleContentValue] = useState({});
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    //File Uploads
    const handleUpload = async (e) => {
        setLoading(true);

        //upload image
        if (e.target.files) {
            const file = e.target.files[0];
            const images = ref(imageStorage, `courses/cat_${v4()}`);
            const fileUpload = await uploadBytes(images, file);
            const filePath = await getDownloadURL(fileUpload.ref);
            setImage(() => filePath);

            // Set image preview
            setImagePreview(URL.createObjectURL(file));
        }
        setLoading(false);
    };
    // Handle downloadable files upload
  const handleFileUpload = async (e, moduleIndex, contentIndex) => {
    setLoading(true);

    if (e.target.files) {
      const file = e.target.files[0];
      const files = ref(imageStorage, `courses/files_${uuidv4()}`);
      const fileUpload = await uploadBytes(files, file);
      const filePath = await getDownloadURL(fileUpload.ref);

      setFormData((prevFormData) => {
        const updatedModules = [...prevFormData.modules];
        const updatedContent = [...updatedModules[moduleIndex].content];
        updatedContent[contentIndex].files.push({
          filename: file.name,
          url: filePath,
        });
        updatedModules[moduleIndex].content = updatedContent;
        return { ...prevFormData, modules: updatedModules };
      });
    }

    setLoading(false);
  };


    ////////////////////////////
    // LOAD COURSE DATA
    ////////////////////////////
    const {courseId} = useParams();

    const handleDeleteImage = async () => {
        setLoading(true);
        if (image) {
            const imageRef = ref(imageStorage, image);
            await deleteObject(imageRef);
            setImage('');
            setImagePreview('');
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchCourseData = async () => {
            setLoading(true)
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_GET_ALL}/${courseId}`
                );
                const data = await response.json();
                setFormData({
                    ...data,
                    modules: data.modules || [],
                    files: data.files || [],
                });
                setDetailValue(data.detail);
                setModuleContentValue(data.detail);
                setImage(data.image);
                setImagePreview(data.image);
            } catch (error) {
            }
            setLoading(false)
        };

        fetchCourseData();
        // eslint-disable-next-line
    }, []);

    ////////////////////////////
    // LOAD CATEGORIES
    ////////////////////////////
    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true)
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_CATEGORIES_GET_ALL}`
                );
                const data = await response.json();
                const filtered = data.filter((category) => category.active === true);
                setCategories(filtered);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
            setLoading(false)
        };

        fetchCategories();
    }, []);
    // En el useEffect donde cargas los datos del curso
    


    ////////////////////////////
    // SELECT CATEGORY
    const handleCategoryChange = (e) => {
        const options = e.target.options;
        const selectedCategories = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedCategories.push(options[i].value);
            }
        }
        setFormData((prevState) => ({...prevState, category: selectedCategories}));
    };


    ////////////////////////////
    // CHANGE SELECT & RANDOM
    ////////////////////////////
    const handleChangeSelect = (e, moduleIndex) => {
        const {value: newOption} = e.target;

        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const currentContent = updatedModules[moduleIndex].content.slice(-1)[0];

            if (!currentContent) {
                return prevFormData;
            }

            switch (newOption) {
                case "video":
                    currentContent.type = "video";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.text = "";
                    currentContent.files = [];
                    currentContent.questions = [];
                    break;
                case "text":
                    currentContent.type = "text";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.files = [];
                    currentContent.questions = [];
                    break;
                case "quiz":
                    currentContent.type = "quiz";
                    currentContent.title = "";
                    currentContent.url = "";
                    currentContent.text = "";
                    currentContent.files = [];
                    currentContent.questions = [initialQuestion];
                    break;
                default:
                    break;
            }

            return {
                ...prevFormData,
                modules: updatedModules,
            };
        });
    };

    const generateUniqueId = () => {
        return uuidv4();
    };

    ////////////////////////////
    // COURSE NAME, DESCRIPTION
    ////////////////////////////
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name' && value.length > 20) {
            alert('El título no puede tener más de 20 caracteres.');
            return;
        }

        if (name === 'description' && value.length > 80) {
            alert('La descripción no puede tener más de 80 caracteres.');
            return;
        }
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    //////////////////////////////////////////////
    // MODULE FUNCTIONS
    //////////////////////////////////////////////
    ////////////////////////////
    // ADD MODULE
    const handleAddModule = () => {
        const newModule = {
            id: `module${formData.modules.length + 1} `,
            name: "",
            content: [],
        };
        setFormData((prevState) => ({
            ...prevState,
            modules: [...prevState.modules, newModule],
        }));
    };
    ////////////////////////////
    // SET MODULE NAME
    const handleModuleChange = (moduleIndex, e) => {
        const {name, value} = e.target;
        setFormData((prevState) => {
            const updatedModules = [...prevState.modules];
            updatedModules[moduleIndex] = {
                ...updatedModules[moduleIndex],
                [name]: value,
            };
            return {...prevState, modules: updatedModules};
        });
    };

    ////////////////////////////
    // DELETE MODULE
    const handleDeleteModule = (moduleIndex) => {
        setFormData((prevState) => {
            const updatedModules = [...prevState.modules];
            updatedModules.splice(moduleIndex, 1);
            return {...prevState, modules: updatedModules};
        });
    };

    //////////////////////////////////////////////
    // CONTENT FUNCTIONS
    //////////////////////////////////////////////
    ////////////////////////////
    // ADD CONTENT
    const handleAddContent = (moduleIndex) => {
        setFormData((prevFormData) => {
            let newContent = {
                ...initialContent,
                id: generateUniqueId(),
                type: "video",
                title: "",
                url: "",
                text: "",
                questions: [],
                files: [],
            };

            const updatedModules = [...prevFormData.modules];
            updatedModules[moduleIndex].content.push(newContent);

            return {
                ...prevFormData,
                modules: updatedModules,
            };
        });
    };

    // Handle rich text editor change
    const handleDetailChange = (value) => {
        setDetailValue(value);
        setFormData((prevData) => ({...prevData, detail: value}));
    };

    ////////////////////////////
    // HANDLE CONTENT CHANGE

    const handleModuleContentChange = (value, moduleIndex, contentIndex) => {
        setModuleContentValue((prevState) => ({
            ...prevState,
            [`${moduleIndex}-${contentIndex}`]: value,
        }));
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedContent = {...updatedModules[moduleIndex].content[contentIndex], text: value};
            updatedModules[moduleIndex].content[contentIndex] = updatedContent;
            return {...prevFormData, modules: updatedModules};
        });
    };

    const handleContentChange = (e, moduleIndex, contentIndex) => {
        const {name, value} = e.target;

        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};

            if (updatedContent.type === "video" || updatedContent.type === "text") {
                updatedContent[name] = value;
            } else if (updatedContent.type === "quiz") {
                const updatedQuestion = {
                    ...updatedContent.questions[0],
                    [name]: value,
                };
                updatedContent.questions = [updatedQuestion];
            }

            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // HANDLE DELETE CONTENT
    const handleDeleteContent = (moduleIndex, contentIndex) => {
        Swal.fire({
            title: "Confirme Acción",
            text: `¿Está seguro que desea eliminar este contenido?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
            }).then((result) => {
                if (result.isConfirmed) {
                    setFormData((prevFormData) => {
                    const updatedModules = [...prevFormData.modules];
                    const updatedModule = {...updatedModules[moduleIndex]};
                    const updatedContent = [...updatedModule.content];
                    updatedContent.splice(contentIndex, 1);
                    updatedModule.content = updatedContent;
                    updatedModules[moduleIndex] = updatedModule;

                return {...prevFormData, modules: updatedModules};
                });
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'El contenido ha sido eliminado.',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        });
    };
    //handle files
    const handleUploadFile = async (e, moduleIndex, contentIndex) => {
        setLoading(true);
        if (e.target.files) {
            const file = e.target.files[0];
            const filesRef = ref(imageStorage, `courses/files_${uuidv4()}`);
            const fileUpload = await uploadBytes(filesRef, file);
            const filePath = await getDownloadURL(fileUpload.ref);

            setFormData((prevFormData) => {
                const updatedModules = [...prevFormData.modules];
                const updatedContent = { ...updatedModules[moduleIndex].content[contentIndex] };
                updatedContent.files = [...updatedContent.files, filePath];
                updatedModules[moduleIndex].content[contentIndex] = updatedContent;
                return { ...prevFormData, modules: updatedModules };
            });
        }
        setLoading(false);
    };

    const handleDeleteFile = (moduleIndex, contentIndex, fileIndex) => {
        setFormData((prevFormData) => {
          const updatedModules = [...prevFormData.modules];
          const updatedContent = [...updatedModules[moduleIndex].content];
          const updatedFiles = [...updatedContent[contentIndex].files];
          updatedFiles.splice(fileIndex, 1);
          updatedContent[contentIndex].files = updatedFiles;
          updatedModules[moduleIndex].content = updatedContent;
          return { ...prevFormData, modules: updatedModules };
        });
    };
    

    ////////////////////////////
    // QUIZ FUNCTIONS
    ////////////////////////////
    ////////////////////////////
    // QUESTION CHANGE
    const handleQuestion = (e, questionIndex, moduleIndex, contentIndex) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {
                ...updatedContent.questions[questionIndex],
                question: value,
            };
            updatedContent.questions[questionIndex] = updatedQuestion;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;
            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // ANSWER CHANGE
    const handleAnswerChange = (
        questionIndex,
        answerIndex,
        e,
        moduleIndex,
        contentIndex
    ) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {...updatedContent.questions[questionIndex]};
            const updatedAnswers = [...updatedQuestion.answers];
            updatedAnswers[answerIndex] = value;
            const updatedQuestionWithAnswers = {
                ...updatedQuestion,
                answers: updatedAnswers,
            };
            const updatedQuestions = [...updatedContent.questions];
            updatedQuestions[questionIndex] = updatedQuestionWithAnswers;

            updatedContent.questions = updatedQuestions;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // SET CORRECT ANSWER
    const handleCorrectAnswerChange = (
        e,
        questionIndex,
        moduleIndex,
        contentIndex
    ) => {
        const {value} = e.target;
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const updatedQuestion = {...updatedContent.questions[questionIndex]};
            updatedQuestion.correctAnswer = updatedQuestion.answers[value];
            updatedContent.questions[questionIndex] = updatedQuestion;
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;
            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // DELETE QUESTION
    const handleDeleteQuestion = (questionIndex, moduleIndex, contentIndex) => {
        Swal.fire({
            title: "Confirme Acción",
            text: `¿Está seguro que desea eliminar esta pregunta?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-button-confirmed',
                cancelButton: 'custom-button-cancel',
            },
            }).then((result) => {
                if (result.isConfirmed) {
                    setFormData((prevFormData) => {
                        const updatedModules = [...prevFormData.modules];
                        const updatedModule = {...updatedModules[moduleIndex]};
                        const updatedContent = {...updatedModule.content[contentIndex]};
                        updatedContent.questions.splice(questionIndex, 1);
                        updatedModule.content[contentIndex] = updatedContent;
                        updatedModules[moduleIndex] = updatedModule;
                    return {...prevFormData, modules: updatedModules};
                });
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'La pregunta ha sido eliminada.',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        });
    };

    ////////////////////////////
    // ADD QUESTION
    const handleAddQuestion = (moduleIndex, contentIndex) => {
        setFormData((prevFormData) => {
            const updatedModules = [...prevFormData.modules];
            const updatedModule = {...updatedModules[moduleIndex]};
            const updatedContent = {...updatedModule.content[contentIndex]};
            const newQuestion = {...initialQuestion, id: generateUniqueId()};

            updatedContent.questions.push(newQuestion);
            updatedModule.content[contentIndex] = updatedContent;
            updatedModules[moduleIndex] = updatedModule;

            return {...prevFormData, modules: updatedModules};
        });
    };

    ////////////////////////////
    // SUBMIT FORM
    ////////////////////////////
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(image)
        const courseData = {
            name: formData.name,
            description: formData.description,
            detail: formData.detail,
            duration: formData.duration,
            image: image === "" ? formData.image : image,
            category: formData.category,
            completed: false,
            modules: formData.modules.map((module) => {
                const formattedModule = {
                    id: module.id,
                    name: module.name,
                    completed: false,
                    content: module.content.map((content) => {
                        let formattedContent = {
                            id: content.id,
                            type: content.type,
                            title: content.title,
                            completed: false,
                            files: content.files,
                        };
                        switch (content.type) {
                            case "video":
                                formattedContent.url = content.url;
                                break;
                            case "text":
                                formattedContent.text = content.text;
                                break;
                            case "quiz":
                                delete formattedContent.title;
                                formattedContent.questions = content.questions.map((question) => {
                                    const formattedQuestion = {
                                        question: question.question,
                                        answers: question.answers,
                                        correctAnswer: question.correctAnswer,
                                    };
                                    delete formattedQuestion.id;
                                    return formattedQuestion;
                                });
                                break;
                            default:
                                break;
                        }
                        return formattedContent;
                    }),
                };
                delete module.id;
                return formattedModule;
            }),
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_POST}/${courseId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(courseData),
                }
            );

            if (response.ok) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "¡Curso modificado con éxito!",
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    window.location.href = "/admin/cursos";
                });
                setFormData(initialFormData);
            } else {
                Swal.fire({
                    title: "¡Ups! Algo salió mal",
                    text: `Lo sentimos, no se pudo modificar el curso en este momento. Por favor, inténtalo de nuevo más tarde`,
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "¡Error de procesamiento!",
                text:
                    "Parece que ocurrió un error mientras procesábamos tu solicitud. Por favor, inténtalo nuevamente más tarde. Si el problema persiste, ponte en contacto con el equipo de soporte para obtener asistencia adicional.",
                icon: "error",
            });
        }
    };

    const modulo = {
        toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {list: "ordered"},
                {list: "bullet"},
                {indent: "-1"},
                {indent: "+1"},
            ],
            ["link", "image", "video"],
        ]
    }

    ////////////////////////////
    // SHOW DATA
    ////////////////////////////
    return (
        <form onSubmit={handleSubmit} className="container mt-0 module-box box pb-40">
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <div className={`content ${loading ? 'loading' : ''}`}>
                <h2>{formData.name}</h2>

                <div className="mb-3">
                    <label className="form-label text-large">Nombre:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                        placeholder="Nombre del curso"
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label mt-2">Descripcion:</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                        placeholder="Describa su curso aqui"
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label mt-2">Detalle del curso:</label>
                    <ReactQuill
                        theme="snow"
                        value={detailValue}
                        onChange={handleDetailChange}
                        modules={modulo}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label text-large">Duración (horas):</label>
                    <input
                        type="number"
                        name="duration"
                        value={formData.duration}
                        onChange={handleInputChange}
                        className="form-control mb-20"
                        placeholder="Ingrese la duracion aproximada del curso en horas"
                    />
                </div>

                {/* Imagen del curso */}
                <label htmlFor="image" className="block mb-2 form-label">
                    Imagen portada (tamaño de imagen sugerida 550px x 260px):
                </label>
                <div className="mb-4">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleUpload}
                    className="form-control mb-20"
                />   
                {imagePreview && (
                <div className="image-container mt-3" style={{ position: "relative", display: "inline-block" }}>
                    <button
                        type="button"
                        onClick={() => {
                            setImage('');
                            setImagePreview('');
                        }}
                        className="delete-button"
                    >
                        &times;
                    </button>
                    
                    <img src={imagePreview} alt="Vista previa de la imagen" className="img-preview" style={{ maxWidth: "100%", borderRadius: "10px" }} />
                </div>
                )}
                </div>
                <div className="mb-3">
                    {categories && (
                        <>
                            <label className="form-label">Seleccione la categoría correspondiente (una o más):</label>
                            <select
                                className="form-control"
                                multiple
                                value={formData.category}
                                onChange={handleCategoryChange}
                            >
                                <option disabled value="">
                                    Select an option
                                </option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                </div>

                {/* Create Module Section Start */}
                {/* {formData.modules.length !== 0 && <h4 className="mt-50">Editar modulos</h4>} */}

                {formData.modules.map((module, moduleIndex) => (
                    <div className="module">
                        <div key={moduleIndex} className="mb-20 boxs py-4 rounded">
                            <div className="container">
                                <div className="border-bottom">
                                    <div className="row">
                                        <div>
                                            <div>
                                                <h6>Nombre del Módulo:</h6>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={module.name}
                                                    onChange={(e) => handleModuleChange(moduleIndex, e)}
                                                    className="form-control form-lila mb-20"
                                                    placeholder="Nombre del Módulo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                {module.content.length !== 0 && (
                                    <h5 className="mt-20">Crear contenido:</h5>
                                )}
                            </div>
                            {module.content.map((content, contentIndex) => (
                                <div key={contentIndex} className="mb-20 rounded module-shadow border-bottom">
                                    <div className="container">
                                        
                                            {/* Selector Option Start */}
                                            <div className="row">
                                                <div className="form-group col-md-10">
                                                    <label className="form-label">Seleccione una opcion de creacion de contenido:</label>
                                                    <select
                                                        className="form-control form-lila"
                                                        value={content.type}
                                                        onChange={(e) => {
                                                            handleChangeSelect(e, moduleIndex);
                                                        }}
                                                    >
                                                        <option value="video">Cargar video</option>
                                                        <option value="text">Cargar articulo</option>
                                                        <option value="quiz">Cargar Test de preguntas y respuestas</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleDeleteContent(moduleIndex, contentIndex);
                                                            }}
                                                            className="btn btn-del-cont">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Selector Option End */}

                                                {/* Video Option Start */}
                                                <div className="container">
                                                {content.type === "video" && (
                                                    <>
                                                        <h5 className="mt-20">Titulo del video:</h5>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            value={content.title || ""}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="Titulo del video"
                                                        />

                                                        <label className="form-label mt-2 mb-1">Url:</label>
                                                        <input
                                                            type="text"
                                                            name="url"
                                                            value={content.url || ""}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="url del video"
                                                        />
                                                    </>
                                                )}
                                                </div>
                                                {/* Video Option End */}

                                                {/* Text Option Start */}
                                                {content.type === "text" && (
                                                    <div>
                                                        <h5 className="mt-20">Título del Articulo</h5>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            value={content.title}
                                                            onChange={(e) => handleContentChange(e, moduleIndex, contentIndex)}
                                                            className="form-control form-lila mb-20"
                                                            placeholder="Titulo del articulo"
                                                        />

                                                        <label className="form-label mt-2 mb-1">Articulo:</label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={
                                                                moduleContentValue[
                                                                    `${moduleIndex}-${contentIndex}`
                                                                    ] || content.text
                                                            }
                                                            onChange={(value) =>
                                                                handleModuleContentChange(
                                                                    value,
                                                                    moduleIndex,
                                                                    contentIndex
                                                                )
                                                            }
                                                            modules={modulo}
                                                            className="quill-lila"
                                                        />
                                                    </div>
                                                )}
                                                {/* Text Option End */}

                                                {/* Quiz Option Start */}
                                                {content.type === "quiz" && (
                                                    <div>
                                                        <div className="container mt-4 quiz style1">
                                                            <h5 className="mt-20">Crear Quiz:</h5>

                                                            {content.questions.map((question, questionIndex) => (
                                                                <div key={questionIndex}
                                                                    className="mb-20 rounded module-shadow">
                                                                    <div className="container">
                                                                        <div className="row contact-widget module-box">
                                                                            <div className="col-md-10  rounded pt-3">
                                                                                <div className="form-group">
                                                                                    <label>Ingrese la pregunta o
                                                                                        trivia:</label>
                                                                                    <textarea
                                                                                        name="question"
                                                                                        className="form-control"
                                                                                        value={question.question}
                                                                                        onChange={(e) =>
                                                                                            handleQuestion(e, questionIndex, moduleIndex, contentIndex)
                                                                                        }
                                                                                        placeholder={`Pregunta...`}
                                                                                    ></textarea>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <label>Escriba las posibles
                                                                                        respuestas:</label>
                                                                                    {question.answers.map((answer, answerIndex) => (
                                                                                        <div key={answerIndex}
                                                                                            className="mb-10">
                                                                                            <input
                                                                                                name="answer"
                                                                                                type="text"
                                                                                                className="form-control form-lila"
                                                                                                value={answer}
                                                                                                onChange={(e) =>
                                                                                                    handleAnswerChange(
                                                                                                        questionIndex,
                                                                                                        answerIndex,
                                                                                                        e,
                                                                                                        moduleIndex,
                                                                                                        contentIndex
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ))}

                                                                                    <div className="form-group">
                                                                                        <label>Seleccione la respuesta
                                                                                            correcta:</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            value={
                                                                                                question.correctAnswer
                                                                                                    ? question.answers.indexOf(question.correctAnswer)
                                                                                                    : ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleCorrectAnswerChange(
                                                                                                    e,
                                                                                                    questionIndex,
                                                                                                    moduleIndex,
                                                                                                    contentIndex
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {!question.correctAnswer && (
                                                                                                <option value="">Select an
                                                                                                    option</option>
                                                                                            )}
                                                                                            {question.answers.map((answer, index) => (
                                                                                                <option key={index}
                                                                                                        value={index}>
                                                                                                    {answer}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="col-md-2 d-flex align-items-center justify-content-center">
                                                                                <div
                                                                                    className="d-flex justify-content-center">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            handleDeleteQuestion(
                                                                                                questionIndex,
                                                                                                moduleIndex,
                                                                                                contentIndex
                                                                                            );
                                                                                        }}
                                                                                        className="btn btn-del-cont "
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            <div
                                                                className="container d-flex justify-content-center contact-widget">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleAddQuestion(moduleIndex, contentIndex);
                                                                    }}
                                                                    className="btn  btn-add btn-add-cont me-2 m-10"
                                                                >
                                                                    Agregar Pregunta
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Quiz Option End */}
                                            </div>
                                            
                                            {/* Archivos descargables */}
                                            <div className="mb-3">
                                            <label className="form-label">Archivos Descargables</label>
                                            <input
                                                type="file"
                                                onChange={(e) => handleFileUpload(e, moduleIndex, contentIndex)}
                                                className="form-control form-lila mb-20"
                                                value={content.file || []}
                                            />
                                                <ul className="list-disc pl-5 mt-2">
                                                {content.files && content.files.length > 0 && (
                                                content.files.map((file, fileIndex) => (
                                                    <li key={fileIndex} className="flex items-center justify-between">
                                                    <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                                        {file.filename}
                                                    </a>
                                                    <div className="image-container mt-3" style={{ position: "relative", display: "inline-block" }}>
                                                        <button
                                                        type="button"
                                                        onClick={() => handleDeleteFile(moduleIndex, contentIndex, fileIndex)}
                                                        className="delete-button-file"
                                                        >
                                                        &times;
                                                        </button>
                                                    </div>
                                                    </li>
                                                ))
                                                )}

                                                {(!content.files || content.files.length === 0) && (
                                                <em>No hay archivos adjuntos</em>
                                                )}
                                                </ul>
                                            </div>
                                            
                                        
                                    </div>
                                </div>
                            ))}

                            <div className="container d-flex justify-content-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleAddContent(moduleIndex);
                                    }}
                                    className="btn btn-secondary btn-add btn-add-cont me-2 m-10"
                                >
                                    Agregar Contenido
                                </button>
                            </div>
                        </div>
                    </div>
                ))}

                <div className="row">
                    <div className="container d-flex justify-content-center text-center">
                        <div className="col-lg-6 col-md-6">
                            <button
                                type="button"
                                onClick={handleAddModule}
                                className="btn btn-secondary btn-add break-words me-2"
                            >
                                Agregar Módulo
                            </button>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <button type="submit" className="btn btn-send pt-50">
                                Guardar Cambios
                            </button>
                        </div>
                    </div>
                    {/* Create Module Section End */}
                </div>
            </div>
        </form>
    );
};

export default ModifyCourseForm;
