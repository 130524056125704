import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

const SliderDefault = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-slider main-home">
                <Slider {...sliderSettings}>
                    <div className="slider-content slide1">
                        <div className="container">
                            <div className="content-part">
                                <h1 className="sl-title">Plataforma integral de experiencias formativas.</h1>
                                <div className="sl-btn">
                                    <Link className="readon orange-btn main-home" to="/cursos">Encontrar cursos</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
                {/* <FeatureStyle1 /> */}
            </div>
        </React.Fragment>
    );
}

export default SliderDefault;