import React from 'react';
import {Link} from 'react-router-dom';

const ButtonLink = ({to, onClick, children}) => {
    return (
        <div>
            <div className="">
                <Link to={to}>
                    <button className="btn-shop" onClick={onClick}>{children}</button>
                </Link>
            </div>
        </div>
    );
}

export default ButtonLink;

