import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';
import AdminAllCourses from './table';

const Main = () => {
    return (
        <React.Fragment>
            <AdminAllCourses className="rs-categories main-home"/>
            <ScrollToTop scrollClassName="scrollup orange-color" />
        </React.Fragment>
    )
}

export default Main;