import React from 'react';
import Header from '../../components/Layout/Header/Header';
import HomeMain from './HomeMain';
//import useUserLocalStorage from "../../components/UseUserLocalStorage/UseUserLocalStorage";
import Login from "../login";
import Footer from "../../components/MSFooter";

const HomePage = () => {
    //const isAuthenticated = useUserLocalStorage();
    const token = localStorage.getItem('userProfile');
    //if (!isAuthenticated) {
    if (!token) {
        console.error('user not authenticated');
        return <Login/>
    }
    return (
        <React.Fragment>
            <Header parentMenu='pages'/>
            <HomeMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default HomePage;
