import {useEffect, useState} from "react";

// Función para decodificar un token JWT (JSON Web Token)
function parseJwt(token) {
    try {
        // Acceder a la propiedad 'token' dentro del objeto 'token'
        return JSON.parse(atob(token.token.split('.')[1]));
    } catch (error) {
        console.error("Error parsing token:", error);
        return null;
    }
}

const useUserLocalStorage = () => {
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        // Función para obtener el ID de usuario del Local Storage
        const getUserIdFromLocalStorage = () => {
            // Obtener el valor almacenado en la clave "userProfile" del Local Storage
            const userProfileString = localStorage.getItem("userProfile");

            // Verificar si userProfileString es null o undefined
            if (!userProfileString) {
                // No se encontró ninguna información de usuario en el Local Storage
                return null;
            }

            // Parsear el valor JSON almacenado en userProfileString
            const userProfile = JSON.parse(userProfileString);

            // Obtener el token del objeto userProfile
            const token = userProfile.token;

            // Verificar si el token es null o undefined
            if (!token) {
                // No se encontró ningún token en el objeto userProfile
                return null;
            }

            // Decodificar el token JWT para obtener el userId
            const decodedToken = parseJwt(token);

            // Obtener el userId del token decodificado
            const userId = decodedToken && decodedToken.userId;
            // Devolver el userId obtenido
            return userId;
        };

        const userIdFromLocalStorage = getUserIdFromLocalStorage();
        setUserId(userIdFromLocalStorage);

        return () => {
            // Cleanup function
        };
    }, []);

    return userId;
};

export default useUserLocalStorage;

