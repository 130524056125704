import React from 'react';
import {useHistory} from 'react-router-dom';

const CategoriesSingle = (props) => {
    const {itemClass, title, image, quantity, btnText, description} = props;
    const history = useHistory();

    const handleCategoryClick = () => {
        history.push(`/cursos?category=${title}`);
    };

    return (
        <div className={itemClass} style={{cursor: 'pointer'}} onClick={handleCategoryClick}>
            <div className="cate-images">
                <img className="crop" src={image} alt={title}/>
                
                <div className="categ-contents" style={{opacity: '0.92'}}>
                <div className="content-wrap pl-10">
                    <h6 className="title pt-5">{title}</h6>
                    <p>{description}</p>
                    
                    {btnText && (
                        <div className="btn2">
                            <button onClick={handleCategoryClick}>{btnText}</button>
                        </div>
                    )}
                </div>
                </div>
            </div>
            
        </div>
    );
};

export default CategoriesSingle;

