import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import ScrollToTop from "../components/Common/ScrollTop";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import axios from "axios";
import Swal from "sweetalert2";
import useUserStore from "../store/useUserStore";
import ButtonLoadingIndicator from "../components/Placeholder/ButtonLoadingIndicator";
import favIcon from "../assets/img/fav-orange.png";
import bannerbg from "../assets/img/banner01.png";
import Footer from "../components/MSFooter";

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    const history = useHistory();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setBaseUrl(window.location.origin)
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const requestBody = {
            email,
            baseUrl
        };

        try {
            console.log(requestBody)
            const response = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/auth/forgotPassword`,
                requestBody
            );

            if (response.status === 200) {
                useUserStore.setState({isAuthenticated: false});
                Swal.fire({
                    position: "center",
                    text: "Se ha enviado un correo a su casilla.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                    timer: 2500,
                }).then(() => {
                    history.push("/login");
                });
            } else {
                await Swal.fire({
                    text: "No podemos recuperar su contraseña en este momento.",
                    showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
                });
            }
        } catch (error) {
            await Swal.fire({
                text: "No podemos recuperar su contraseña en este momento.",
                showConfirmButton: true,
                    confirmButtonText: `OK`,
                    customClass: {
                        confirmButton: 'custom-button-confirmed',
                    },
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon}/>
            </Helmet>
            <Header
                parentMenu="forgot-password"
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Recuperar mi Contraseña"
                pageName="Recuperar mi Contraseña"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* Login Part Start */}
            <div className="back pt-4">
                <div className="rs-login md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="noticed">
                            <div className="main-part">
                                <div className="method-account">
                                    <form>
                                        <input
                                            type="email"
                                            name="E-mail"
                                            placeholder="E-mail"
                                            value={email}
                                            onChange={handleEmailChange}
                                            required
                                        />
                                        {loading ? (
                                            <ButtonLoadingIndicator/>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="readon submit-btn"
                                                onClick={handleSubmit}
                                            >
                                                Recuperar contraseña
                                            </button>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop scrollClassName="scrollup orange-color"/>
            <Footer/>
        </React.Fragment>
    );
};

export default ForgotPassword;
