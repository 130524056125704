import React, {useEffect, useState} from "react";
import Header from "../../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import Main from "./main";
import {useParams} from "react-router-dom/cjs/react-router-dom.min";
import useDataStore from "../../../store/store";
import Footer from "../../../components/MSFooter";
// Image
import bannerbg from "../../../assets/img/banner02.png";

const CustomPlaceholder = () => (
    <div className="d-flex justify-content-center align-items-center vh-100">
        <div
            className="text-center"
            style={{minWidth: "100%", minHeight: "700px", marginTop: "120px"}}
        >
            <div
                className="spinner-grow text-dark"
                role="status"
                style={{width: "2rem", height: "2rem"}}
            >
                <span className="sr-only">Loading...</span>
            </div>
            <div
                className="spinner-grow text-dark mx-3"
                role="status"
                style={{width: "3rem", height: "3rem"}}
            >
                <span className="sr-only">Loading...</span>
            </div>
            <div
                className="spinner-grow text-dark"
                role="status"
                style={{width: "2rem", height: "2rem"}}
            >
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    </div>
);

const Course = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");
    const setData = useDataStore((state) => state.setData);

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_COURSES_GET_ALL}/${id}`
                );
                const data = await response.json();
                setTitle(data.name);
                setData(data);
            } catch (error) {
                console.error("Error fetching course data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseData();
    }, [id, setData]);

    return (
        <React.Fragment>
            <Header parentMenu='courses' />
            <SiteBreadcrumb pageTitle={loading ? "" : title} titleClass="page-title white-color" pageName="Course Details" breadcrumbsImg={bannerbg} />
            {loading ? <CustomPlaceholder/> : <Main/>}
            <Footer/>
        </React.Fragment>
    );
};

export default Course;
