import React, {useEffect, useState} from "react";
import CourseSingleTwo from "../Courses/CourseSingleTwo";
import useUserLocalStorage from "../UseUserLocalStorage/UseUserLocalStorage";
import courseImg1 from "../../assets/img/courses/1.jpg";
import {Link} from 'react-router-dom';

const LastCourses = () => {
    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const userId = useUserLocalStorage();

    useEffect(() => {
        const fetchDataAndCategories = async () => {
            try {
                // Fetch the latest courses data
                const coursesResponse = await fetch(
                    `${process.env.REACT_APP_ROOT_URL}/courses/latest`
                );
                const coursesData = await coursesResponse.json();

                // Set the fetched courses data
                setFetchData(coursesData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDataAndCategories();
    }, [userId]);

    const renderCoursePanel = () => {
        return (
            <>
                <div className="row">
                    <div className="text-center pb-50 pt-20">
                        <h5>SELECCIONAR CURSOS</h5>
                        <h3>Explora los últimos Cursos</h3>
                    </div>
                    {fetchData.map((course) => (
                        <div key={course.id} className="col-lg-3 col-md-6">
                            <CourseSingleTwo
                                key={course.id}
                                courseClass="courses-item md-mb-30"
                                courseImg={course.image === "" ? courseImg1 : course.image}
                                courseTitle={truncateText(course.name, 20) || "Curso en desarrollo"}
                                courseDuration={course.duration || "10"}
                                courseId={course.id || "112"}
                                courseDetail={truncateText(course.description, 40)}
                                courseCategory={
                                    course.categories && course.categories.length > 0
                                        ? course.categories.join(", ")
                                        : "Web development"
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className="sl-btn text-center pt-50">
                    <Link className="readon orange-btn main-home" to="/cursos">TODOS LOS CURSOS</Link>
                </div>
            </>
        );
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <div
            id="rs-popular-course"
            className="rs-popular-courses style1 orange-style pt-50 pb-50 md-pt-70 md-pb-50"
        >
            <div className="container">
                { renderCoursePanel()}
            </div>
        </div>
    );
};

export default LastCourses;





